import React from 'react';
import {Tag} from 'antd';

export const PaymentState: React.FC<{state?: string}> = ({state}) => {
  let color = 'default';

  if (state) {
    if (
      ['EXPIRED', 'PENDING', 'REFUND_PENDING', 'PAYOUT_PENDING'].includes(state)
    ) {
      color = 'default';
    }

    if (['RECEIVED', 'PAYOUT_DONE'].includes(state)) color = 'green';

    if (['REFUNDED', 'CHARGEBACK'].includes(state)) {
      color = 'blue';
    }

    if (
      [
        'FAILED',
        'FAILED_CNP_GENERIC',
        'FAILED_CNP_DATA_INVALID',
        'FAILED_CNP_CARD_INVALID',
        'FAILED_CNP_CARD_DATA_ERROR',
        'FAILED_CNP_CARD_EXPIRED',
        'FAILED_CNP_CARD_MALICIOUS',
        'FAILED_CNP_CARD_FUNDS',
        'FAILED_CNP_CARD_RESTRICTED',
        'FAILED_CNP_PLATFORM_CONSTRAINT',
        'FAILED_CNP_BILING_COUNTRY_MISMATCH',
      ].includes(state)
    ) {
      color = 'red';
    }
  }

  return <Tag color={color}>{state}</Tag>;
};
