import React, {useCallback, useState} from 'react';
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
} from '@refinedev/core';
import {List, NumberField, useTable} from '@refinedev/antd';
import {IPayout, IPage, IPayment} from '../../../interfaces';
import {Button, Card, Col, Modal, Row, Space, Table, Typography} from 'antd';
import {PaymentState} from '../../../layout/components';
import {dateFmt} from '../../../utils/date';
import {IPayoutFilterVariables, PayoutFilter} from '../components';
import {OrderItem} from '../../order-item/components';
import {DollarCircleOutlined} from '@ant-design/icons';
import {PayoutService} from '../service';
import {axiosInstance} from '@refinedev/simple-rest';
import {AxiosInstance} from 'axios';

const payoutService = new PayoutService(axiosInstance as AxiosInstance);

export const PayoutList: React.FC<IResourceComponentsProps> = () => {
  const {tableProps, searchFormProps, filters, setFilters, tableQueryResult} =
    useTable<IPage<IPayout>, HttpError, IPayoutFilterVariables>({
      resource: 'payouts',
      pagination: {
        current: 1,
        pageSize: 10,
      },
      onSearch: (params: IPayoutFilterVariables) => {
        const filters: CrudFilters = [];
        const {payoutId, userId, orderIds, states, payoutType, createdAt} =
          params;

        filters.push({
          field: 'payout_id',
          operator: 'eq',
          value: payoutId,
        });

        filters.push({
          field: 'userId',
          operator: 'eq',
          value: userId,
        });

        filters.push({
          field: 'order_ids',
          operator: 'in',
          value: orderIds,
        });

        filters.push({
          field: 'states',
          operator: 'in',
          value: states,
        });

        filters.push({
          field: 'payout_type',
          operator: 'eq',
          value: payoutType,
        });

        filters.push(
          {
            field: 'paidAt',
            operator: 'gte',
            value: createdAt
              ? createdAt[0].startOf('day').toISOString()
              : undefined,
          },
          {
            field: 'createdAt',
            operator: 'lte',
            value: createdAt
              ? createdAt[1].endOf('day').toISOString()
              : undefined,
          }
        );

        return filters;
      },
    });

  const {refetch} = tableQueryResult;

  const [disburseModalOpen, setReimburseModalOpen] = useState<{
    open: boolean;
    payoutId?: string;
  }>({open: false});
  const [loadingReimburse, setLoadingReimburse] = useState<boolean>(false);

  const disbursePayout = useCallback(() => {
    if (disburseModalOpen.payoutId) {
      setLoadingReimburse(true);
      payoutService
        .disbursePayout(disburseModalOpen.payoutId)
        .then(() => {
          setLoadingReimburse(false);
          refetch().then(() => {
            setReimburseModalOpen({open: false});
          });
        })
        .catch(() => setLoadingReimburse(false));
    }
  }, [disburseModalOpen, refetch, setLoadingReimburse, setReimburseModalOpen]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={24} xs={24}>
          <Card title="Filter">
            <PayoutFilter
              formProps={searchFormProps}
              setFilters={setFilters}
              filters={filters || []}
              loading={!!tableProps.loading}
            />
          </Card>
        </Col>
        <Col xl={18} xs={24}>
          <List canCreate={false}>
            <Table
              {...tableProps}
              rowKey="id"
              expandable={{
                expandedRowRender: (record) => (
                  <OrderItem
                    orderItemId={(record as any).orderItemId as string}
                    orderId={(record as any).orderId as string}
                  />
                ),
              }}
            >
              <Table.Column<IPayout>
                key="id"
                title="ID"
                render={(record) => (
                  <Space>
                    <Typography.Text
                      style={{wordBreak: 'inherit', whiteSpace: 'nowrap'}}
                    >
                      {record.id}
                    </Typography.Text>
                  </Space>
                )}
              />

              <Table.Column<IPayout>
                key="state"
                title="Payment State"
                render={(record: IPayout) => (
                  <Space>
                    <PaymentState state={record.payment.state} />
                  </Space>
                )}
              />

              <Table.Column<IPayout>
                key="orderId"
                title="Order ID"
                render={(record: IPayout) => (
                  <Space>
                    <Typography.Text
                      style={{wordBreak: 'inherit', whiteSpace: 'nowrap'}}
                    >
                      {record.orderId}
                    </Typography.Text>
                  </Space>
                )}
              />

              <Table.Column<IPayout>
                key="createdAt"
                title="Created At"
                render={(record: IPayout) => (
                  <Space>
                    <Typography.Text>
                      {dateFmt(new Date(record.createdAt))}
                    </Typography.Text>
                  </Space>
                )}
              />

              <Table.Column<IPayout>
                key="total"
                title="Total"
                fixed="right"
                render={(record: IPayout) => (
                  <NumberField
                    value={record.payment.total}
                    options={{
                      style: 'currency',
                      currency: 'USD',
                    }}
                    style={{
                      whiteSpace: 'nowrap',
                      color:
                        parseFloat(record.payment.total) < 0
                          ? 'red'
                          : 'inherit',
                    }}
                  />
                )}
              />

              <Table.Column<IPayment>
                title="Actions"
                fixed="right"
                render={(record: IPayout) =>
                  record.payment.state === 'PAYOUT_PENDING' && (
                    <Space>
                      <Button
                        type="primary"
                        size="small"
                        block
                        onClick={() =>
                          setReimburseModalOpen({
                            open: true,
                            payoutId: record.id,
                          })
                        }
                      >
                        <DollarCircleOutlined />
                        Reimburse
                      </Button>
                    </Space>
                  )
                }
              />
            </Table>
          </List>
        </Col>
      </Row>
      <Modal
        open={disburseModalOpen.open}
        title={`Refund payout: ${disburseModalOpen.payoutId}`}
        confirmLoading={loadingReimburse}
        onOk={() => disbursePayout()}
        onCancel={() => setReimburseModalOpen({open: false})}
      >
        <Typography.Text style={{display: 'block'}}>
          Are you sure you want to refund this payout?
        </Typography.Text>
      </Modal>
    </>
  );
};
