import React, {useState} from 'react';
import {IOrder} from '../../../../interfaces';
import {Button, Card, Input, message, Space, Typography} from 'antd';
import {OrderService} from '../../service';
import {axiosInstance} from '@refinedev/simple-rest';
import {AxiosError, AxiosInstance} from 'axios';
import {Emoji} from 'layout/components';

const {TextArea} = Input;

const orderService = new OrderService(axiosInstance as AxiosInstance);

export const OrderNotes: React.FC<{
  order: IOrder;
}> = ({order}) => {
  const [text, setText] = useState<string | undefined>(order?.observations);
  const [isLoading, setLoading] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const onSave = async () => {
    if (text) {
      try {
        setLoading(true);
        await orderService.setNotes(order.id, text);
        messageApi.open({
          type: 'success',
          content: (
            <>
              Notes updated <Emoji emoji="👌" />
            </>
          ),
        });
      } catch (e) {
        setLoading(false);
        messageApi.open({
          type: 'error',
          content: `Could not assign logistic type: ${
            (e as AxiosError<{error: string; message: string}>).response?.data
              ?.error
          }`,
        });
        return;
      }
      setLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Card
        title={
          <Space
            direction="horizontal"
            style={{display: 'flex', justifyContent: 'space-between'}}
          >
            <Typography.Text>
              <Emoji emoji="📝" style={{marginRight: 8}} />
              Notes
            </Typography.Text>
            <Button
              type="primary"
              size="middle"
              disabled={isLoading}
              loading={isLoading}
              onClick={onSave}
            >
              Save <Emoji emoji="💾" style={{marginLeft: 4}} />
            </Button>
          </Space>
        }
      >
        <TextArea
          rows={4}
          value={text}
          disabled={isLoading}
          onChange={(e) => setText(e.target.value)}
        />
      </Card>
    </>
  );
};
