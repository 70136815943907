import React, {useCallback, useContext, useMemo, useState} from 'react';
import classNames from 'classnames';
import {
  Breadcrumb,
  Button,
  Col,
  Collapse,
  Form,
  InputNumber,
  message,
  Row,
  Space,
  Tooltip,
} from 'antd';
import {
  ICategory,
  ICourier,
  ICourierCategorySetting,
  ICourierCategorySettingConfig,
  IOrderServiceType,
  ISubCategory,
} from '../../../../interfaces';
import {Emoji} from '../../../../layout/components';
import {ColorModeContext} from '../../../../layout/contexts/color-mode';
import {CourierService, SaveCourierCategorySettingRequest} from '../../service';
import {axiosInstance} from '@refinedev/simple-rest';
import {AxiosInstance, AxiosError} from 'axios';

import './index.scss';

type HeaderColorState = 'success' | 'warning';

const courierService = new CourierService(axiosInstance as AxiosInstance);

export const CourierCategorySettingConfig: React.FC<{
  categorySettingConfig?: ICourierCategorySettingConfig;
  orderServiceType: IOrderServiceType;
  courier: ICourier;
  category: ICategory;
  subCategory?: ISubCategory;
  onAfterSave: () => void;
}> = ({
  categorySettingConfig,
  orderServiceType,
  category,
  subCategory,
  courier,
  onAfterSave,
  ...props
}) => {
  const {mode} = useContext(ColorModeContext);
  const [deliveryDays, setDeliveryDays] = useState<number>(
    categorySettingConfig ? categorySettingConfig.deliveryDays : 0
  );
  const [isSaving, setSaving] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const headerColor = useMemo<HeaderColorState | undefined>(() => {
    if (!categorySettingConfig && deliveryDays) return 'warning';
    if (!categorySettingConfig) return undefined;

    if (
      categorySettingConfig.deliveryDays === 0 ||
      categorySettingConfig.deliveryDays !== deliveryDays
    ) {
      return 'warning';
    }

    if (categorySettingConfig.deliveryDays === deliveryDays) {
      return 'success';
    }
  }, [categorySettingConfig, deliveryDays]);

  const saveConfig = useCallback(
    ({deliveryDays}: {deliveryDays: number}) => {
      if (!isSaving) {
        setSaving(true);

        const categorySettingConfig: {
          categoryId: string;
          subCategoryId?: string;
          deliveryDays: number;
        } = {
          categoryId: category.id,
          deliveryDays,
        };

        if (subCategory) {
          categorySettingConfig.subCategoryId = subCategory.id;
        }

        const request: SaveCourierCategorySettingRequest = {
          courierId: courier.id,
          orderServiceType,
          settingConfigs: [categorySettingConfig],
        };

        courierService
          .saveCourierCategorySetting(request)
          .then(() => {
            messageApi
              .open({
                type: 'success',
                content: `Successfully saved (${orderServiceType}) (${category.id}) courier category settings`,
              })
              .then(() => {
                setSaving(false);
                onAfterSave();
              });
          })
          .catch((e) => {
            messageApi
              .open({
                type: 'error',
                content: `Could not set (${orderServiceType}) (${
                  category.id
                }) courier category setting: ${
                  (e as AxiosError<{error: string; message: string}>).response
                    ?.data?.error
                }`,
              })
              .then(() => {
                setSaving(false);
              });
          });
      }
    },
    [
      isSaving,
      setSaving,
      courier,
      orderServiceType,
      category,
      subCategory,
      onAfterSave,
    ]
  );

  const extra = useMemo(() => {
    let statusEmoji;
    let subCategoriesEmoji;

    if (subCategory) {
      subCategoriesEmoji = (
        <Emoji emoji={'🔗'} size={18} style={{top: 10, display: 'inherit'}} />
      );
    }

    if (headerColor) {
      if (headerColor === 'success') {
        statusEmoji = (
          <Emoji emoji={'✅'} size={18} style={{top: 10, display: 'inherit'}} />
        );
      }
      if (headerColor === 'warning') {
        statusEmoji = (
          <Emoji emoji={'✏️'} size={18} style={{top: 10, display: 'inherit'}} />
        );
      }
    }

    return (
      <div style={{position: 'absolute', right: 16}}>
        <Space direction="horizontal">
          {subCategoriesEmoji}
          {statusEmoji}
        </Space>
      </div>
    );
  }, [headerColor, category, subCategory]);

  const button = useMemo(() => {
    return (
      <Button
        type="primary"
        size="large"
        disabled={isSaving || headerColor === 'success'}
        loading={isSaving}
        onClick={() => saveConfig({deliveryDays})}
        style={{display: 'block', marginLeft: 'auto'}}
      >
        Save <Emoji emoji="💾" style={{marginLeft: 4}} />
      </Button>
    );
  }, [isSaving, deliveryDays, headerColor]);

  const breadcrumbItems = [
    {
      title: `${category.name} ${!subCategory ? '(' + category.id + ')' : ''}`,
    },
  ];

  if (subCategory)
    breadcrumbItems.push({
      title: `${subCategory.name} (${subCategory.id})`,
    });

  return (
    <>
      {contextHolder}
      <Collapse.Panel
        key={category.id}
        className={classNames(headerColor, {
          dark: mode === 'dark',
          light: mode === 'light',
          'courier-subcategory-setting': !!subCategory,
        })}
        showArrow
        header={
          <Space direction="horizontal">
            <Breadcrumb items={breadcrumbItems} style={{margin: 0}} />
            {!!subCategory && (
              <Tooltip
                placement="top"
                title={subCategory.description}
                trigger="hover"
              >
                {' '}
                <Emoji
                  emoji={'ℹ️'}
                  style={{top: 10, display: 'inherit', cursor: 'pointer'}}
                />{' '}
              </Tooltip>
            )}
          </Space>
        }
        extra={extra}
        {...props}
      >
        <div>
          <Space direction="vertical" style={{width: '100%'}}>
            <Row gutter={[16, 16]}>
              <Col lg={24} style={{width: '100%'}}>
                <div style={{display: 'block'}}>
                  <Form
                    name={`courier-category-setting-${category.id}`}
                    form={form}
                    labelCol={{span: 4}}
                    wrapperCol={{span: 20}}
                    initialValues={{
                      [`courier-category-setting-${category.id}-delivery-days`]:
                        categorySettingConfig
                          ? categorySettingConfig.deliveryDays
                          : 0,
                    }}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Delivery Days"
                      name={`courier-category-setting-${category.id}-delivery-days`}
                      rules={[{required: true}]}
                    >
                      <InputNumber
                        width="100%"
                        addonAfter="days"
                        value={deliveryDays}
                        disabled={isSaving}
                        onChange={(value: number | null) => {
                          if (typeof value === 'number') {
                            setDeliveryDays(value);
                          }
                        }}
                      />
                    </Form.Item>
                  </Form>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Space
                  direction="horizontal"
                  style={{width: '100%', justifyContent: 'space-between'}}
                >
                  <Space direction="horizontal" style={{width: '100%'}}>
                    {button}
                  </Space>
                </Space>
              </Col>
            </Row>
          </Space>
        </div>
      </Collapse.Panel>
    </>
  );
};

export const CourierCategorySettingConfigs: React.FC<{
  categorySetting?: ICourierCategorySetting;
  orderServiceType: IOrderServiceType;
  courier: ICourier;
  categories: ICategory[];
  onAfterSave: () => void;
}> = ({
  categorySetting,
  orderServiceType,
  courier,
  categories,
  onAfterSave,
}) => {
  const {mode} = useContext(ColorModeContext);

  const rows = useMemo(() => {
    return categories.flatMap((category) => {
      const foundConfigSettingConfig = categorySetting?.configs.find(
        (conf) => conf.category.id === category.id && !conf.subCategory
      );

      const settingConfigs = [
        <CourierCategorySettingConfig
          key={category.id}
          categorySettingConfig={foundConfigSettingConfig}
          orderServiceType={orderServiceType}
          category={category}
          courier={courier}
          onAfterSave={onAfterSave}
        />,
      ];

      if (category.subCategories.length) {
        category.subCategories.forEach((sc) => {
          const foundSubConfigSettingConfig = categorySetting?.configs.find(
            (conf) =>
              conf.category.id === category.id &&
              conf.subCategory &&
              conf.subCategory.id === sc.id
          );

          settingConfigs.push(
            <CourierCategorySettingConfig
              key={sc.id}
              orderServiceType={orderServiceType}
              categorySettingConfig={foundSubConfigSettingConfig}
              category={category}
              subCategory={sc}
              courier={courier}
              onAfterSave={onAfterSave}
            />
          );
        });
      }

      return settingConfigs;
    });
  }, [categories, mode, onAfterSave]);

  return (
    <Space direction="vertical" style={{width: '100%'}} size="large">
      <Collapse accordion>{rows}</Collapse>
    </Space>
  );
};
