import {DataProvider} from '@refinedev/core';
import {AxiosInstance} from 'axios';
import {axiosInstance, generateFilter} from '@refinedev/simple-rest';
import {UserService} from './service';
import {unflatten} from 'flat';

export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance as AxiosInstance
): Omit<
  Required<DataProvider>,
  | 'deleteOne'
  | 'getMany'
  | 'createMany'
  | 'updateMany'
  | 'deleteMany'
  | 'custom'
> => {
  const userService = new UserService(httpClient);

  return {
    create: async ({variables}) => {
      const {firstName, lastName, email, altUserId, password} = unflatten<
        any,
        Record<string, string>
      >(variables);

      const user = await userService.saveUser({
        firstName,
        lastName,
        email,
        altUserId,
        password,
      });

      return {
        data: user as any,
      };
    },
    update: async ({id, variables}) => {
      const {firstName, lastName, email, altUserId, password} = unflatten<
        any,
        Record<string, string>
      >(variables);

      const user = await userService.saveUser({
        id: id as string,
        firstName,
        lastName,
        email,
        altUserId,
        password,
      });

      return {
        data: user as any,
      };
    },
    getList: async ({pagination, filters}) => {
      const queryFilters = generateFilter(filters);

      const users = await userService.getUsers(
        pagination?.current as number,
        pagination?.pageSize as number,
        queryFilters.user_id,
        queryFilters.email,
      );

      return {
        data: users,
        total: users.length,
      };
    },
    /*create: async ({variables}) => {
      const {email, password, verified, courierAddress} = unflatten<
        any,
        Record<string, any>
      >(variables);

      const courier = await userService.saveCourier({
        email,
        password,
        verified,
        courierAddress,
      });

      return {
        data: courier,
      };
    },
    update: async ({id, variables}) => {
      const vars = unflatten<any, Record<string, any>>(variables);

      const {email, password, verified, courierAddress} = vars;

      const courier = await userService.saveCourier({
        id: id as string,
        email,
        password,
        verified,
        courierAddress,
      });

      return {
        data: courier,
      };
    },*/
    getOne: async ({id}) => {
      const user = await userService.getUser(id as string);

      return {
        data: user,
      };
    },
    getApiUrl: () => apiUrl,
  };
};
