import {AxiosInstance} from 'axios';
import {vars} from '../../vars';
import {TOKEN_KEY} from '../../authProvider';

export class PayoutService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }
  public async getPayouts(
    page: number,
    size: number,
    payoutId?: string,
    orderIds: string[] = [],
    states: string[] = [],
    createdAt: [string, string] | [] = [],
    payoutType?: 'FLIGHT' | 'COURIER'
  ) {
    const query = new URLSearchParams();
    query.set('page', `${page}`);
    query.set('size', `${size}`);
    if (payoutId) query.set('payout_id', payoutId);
    if (payoutType) query.set('payout_type', payoutType);
    if (orderIds) {
      orderIds.forEach((o) => query.append('order_ids', o));
    }
    if (states) {
      states.forEach((s) => query.append('states', s));
    }
    if (createdAt[0]) query.set('created_at_gte', createdAt[0]);
    if (createdAt[1]) query.set('created_at_lte', createdAt[1]);

    const url = `${
      vars.baseApiUrl
    }/logistics/admin/checkout/payouts?${query.toString()}`;
    const {data} = await this.httpClient.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });

    return data;
  }

  public async disbursePayout(payoutId: string) {
    const url = `${vars.baseApiUrl}/logistics/admin/checkout/payout/${payoutId}/disburse`;
    const {data} = await this.httpClient.post(`${url}`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });

    return data;
  }
}
