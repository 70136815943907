import React, {useMemo} from 'react';
import {Typography} from 'antd';
import {dateFmt} from '../../../../utils/date';
import {Emoji} from '../../../../layout/components';

type Props = {
  beforeDate: string;
};

const SEVEN_DAYS = 6.048e8;
// const THIRTY_DAYS = 2.592e9;

export const OrderBeforeDate: React.FC<Props> = ({beforeDate: _beforeDate}) => {
  const beforeDate = new Date(_beforeDate);

  const hasWarning = useMemo<boolean>(() => {
    const nowDate = new Date();

    const diff = beforeDate.getTime() - nowDate.getTime();

    return diff > 0 && diff <= SEVEN_DAYS;
  }, [beforeDate]);

  const hasError = useMemo<boolean>(() => {
    const nowDate = new Date();
    return beforeDate.getTime() - nowDate.getTime() <= 0;
  }, [beforeDate]);

  return (
    <Typography.Text
      style={{
        wordBreak: 'inherit',
        whiteSpace: 'nowrap',
        color: hasError ? '#ff4d4f' : hasWarning ? '#faad14' : 'unset',
      }}
    >
      {dateFmt(beforeDate)}
      {hasWarning && <Emoji size={18} emoji="⚠️" style={{marginLeft: 8}} />}
      {hasError && <Emoji size={18} emoji="❌" style={{marginLeft: 8}} />}
    </Typography.Text>
  );
};
