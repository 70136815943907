import {AuthBindings} from '@refinedev/core';
import axios from 'axios';
import {vars} from './vars';
import {JwtUtil} from './utils/jwt';

export type IUser = {
  id: number;
  name: string;
  avatar: string;
};

export const TOKEN_KEY = 'qempo-admin-auth-jwt';

export const authProvider: AuthBindings = {
  login: async ({email, password}) => {
    try {
      const {headers} = await axios.post(
        `${vars.baseApiUrl}/usher/admin/auth/login`,
        {
          email,
          password,
        }
      );
      const token = headers['authorization']?.replace('Bearer ', '');

      localStorage.setItem(TOKEN_KEY, token);

      return {
        success: true,
        redirectTo: '/orders',
      };
    } catch (e) {
      return {
        success: false,
        error: {
          name: 'LoginError',
          message: 'Invalid username or password',
        },
      };
    }
  },
  logout: async () => {
    localStorage.removeItem(TOKEN_KEY);
    return {
      success: true,
      redirectTo: '/login',
    };
  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token && JwtUtil.validateExp(token)) {
      return {
        authenticated: true,
      };
    }

    localStorage.removeItem(TOKEN_KEY);
    return {
      authenticated: false,
      redirectTo: '/login',
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      const jwtToken = JwtUtil.parseToken(token);

      return {
        id: 1,
        name: jwtToken.payload.admin.email,
        avatar:
          'https://i.pinimg.com/originals/4d/f1/fe/4df1fed4600c9872b008f9c94bad8b28.jpg',
      } satisfies IUser;
    }
    return null;
  },
  onError: async (error) => {
    console.error(error);
    return {error};
  },
};
