import React from 'react';
import {Emoji} from '../../../layout/components';

import logoWhite from '../../../assets/img/white-logo.svg';
import world from '../../../assets/img/world.png';
import './style.css';

export const WelcomePage: React.FC = () => {
  return (
    <div className="welcomeWrap">
      <div className="logoWrap">
        <img src={logoWhite} className="logo" alt="Qempo" />
        <a
          href="/login"
          style={{
            textDecoration: 'none',
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          <span style={{textDecoration: 'underline'}}>Login</span>
          <Emoji size={28} emoji="📦" style={{marginLeft: 4}} />
        </a>
      </div>
      <img src={world} className="world" alt="Qempo" />
    </div>
  );
};
