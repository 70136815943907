import React from 'react';
import {IResourceComponentsProps} from '@refinedev/core';
import {List, EditButton, useTable} from '@refinedev/antd';
import {IOrder, IUser} from '../../../interfaces';
import {Space, Table, Typography} from 'antd';
import {Emoji} from '../../../layout/components';

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const {tableProps} = useTable<IUser>({
    resource: 'users',
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id" pagination={false}>
        <Table.Column<IUser>
          key="id"
          title="ID"
          render={(record) => (
            <Space>
              <Typography.Text style={{wordBreak: 'inherit'}}>
                {record.id}
              </Typography.Text>
            </Space>
          )}
        />
        <Table.Column<IUser>
          key="email"
          title="Email"
          render={(record) => (
            <Space>
              <Typography.Text style={{wordBreak: 'inherit'}}>
                {record.email}
              </Typography.Text>
            </Space>
          )}
        />
        <Table.Column<IUser>
          key="firstName"
          title="First name"
          render={(record) => (
            <Space>
              <Typography.Text style={{wordBreak: 'inherit'}}>
                {record.firstName}
              </Typography.Text>
            </Space>
          )}
        />
        <Table.Column<IUser>
          key="lastName"
          title="Last name"
          render={(record) => (
            <Space>
              <Typography.Text style={{wordBreak: 'inherit'}}>
                {record.lastName}
              </Typography.Text>
            </Space>
          )}
        />
        <Table.Column<IUser>
          key="altUserId"
          title="Alternative userId"
          render={(record) => (
            <Space>
              <Typography.Text style={{wordBreak: 'inherit'}}>
                {record.altUserId}
              </Typography.Text>
            </Space>
          )}
        />
        <Table.Column<IOrder>
          fixed="right"
          title="Actions"
          align="center"
          render={(_, record) => (
            <Space>
              <EditButton
                icon={<Emoji emoji="✏️" />}
                hideText
                size="middle"
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
