import React, {useCallback, useEffect, useState} from 'react';
import {IResourceComponentsProps} from '@refinedev/core';
import {useStepsForm, Create, SaveButton} from '@refinedev/antd';
import {IAdmin1, IAdmin2, IAdmin3, ICourier} from '../../../interfaces';
import {Button, Col, Form, Input, Radio, Row, Select, Steps} from 'antd';
import {axiosInstance} from '@refinedev/simple-rest';
import {GeographyService} from '../../geography/service';
import {AxiosInstance} from 'axios';

export const CourierCreate: React.FC<IResourceComponentsProps> = () => {
  const geographyService = new GeographyService(axiosInstance as AxiosInstance);

  const {
    current,
    gotoStep,
    stepsProps,
    formProps,
    saveButtonProps,
    queryResult,
  } = useStepsForm<ICourier>();

  const [country, setCountry] = useState('US');
  const [admins1, setAdmins1] = useState<{label: string; value: string}[]>();
  const [admins2, setAdmins2] = useState<{label: string; value: string}[]>();
  const [admins3, setAdmins3] = useState<{label: string; value: string}[]>();

  const getAdmins1 = useCallback(
    (country: string) => {
      setAdmins1([]);
      setAdmins2([]);
      setAdmins3([]);
      geographyService.getAdmin1(country).then((admins1) => {
        setAdmins1(
          admins1.map((admin1: IAdmin1) => ({
            label: admin1.name,
            value: admin1.id,
          }))
        );
      });
    },
    [geographyService]
  );

  const getAdmins2 = useCallback(
    (admin1: string) => {
      setAdmins2([]);
      setAdmins3([]);
      geographyService.getAdmin2(admin1).then((admins2) => {
        setAdmins2(
          admins2.map((admin2: IAdmin2) => ({
            label: admin2.name,
            value: admin2.id,
          }))
        );
      });
    },
    [geographyService]
  );

  const getAdmins3 = useCallback(
    (admin2: string) => {
      geographyService.getAdmin3(admin2).then((admins2) =>
        setAdmins3(
          admins2.map((admin3: IAdmin3) => ({
            label: admin3.name,
            value: admin3.id,
          }))
        )
      );
    },
    [geographyService]
  );

  useEffect(() => {
    getAdmins1('US');
  }, [setAdmins1]);

  const formList = [
    <Row key="account" gutter={20}>
      <Col sm={24} md={12}>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col sm={24} md={12}>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              type: 'string',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col sm={24} md={12}>
        <Form.Item label="Verified" name="verified">
          <Radio.Group>
            <Radio value={true}>Verified</Radio>
            <Radio value={false}>Not Verified</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col sm={24} md={12}>
        <Form.Item label="Has Customs" name="hasCustoms">
          <Radio.Group>
            <Radio value={true}>Has Customs</Radio>
            <Radio value={false}>Not</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
    </Row>,
    <Row key="settings" gutter={20}>
      <Col xs={0} sm={0} md={4} />
      <Col xs={24} sm={24} md={16}>
        <Form.Item
          label="Name"
          name="courierAddress.name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Row key="settings" gutter={20}>
          <Col xs={8}>
            <Form.Item
              label="Phone country"
              name="courierAddress.phoneCountry"
              rules={[{required: true}]}
            >
              <Select
                allowClear
                placeholder="Phone Country"
                options={[
                  {label: 'United States', value: 'US'},
                  {label: 'Peru', value: 'PE'},
                  {label: 'Colombia', value: 'CO'},
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={16}>
            <Form.Item
              label="Phone"
              name="courierAddress.phone"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Country"
          name="courierAddress.country"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            allowClear
            placeholder="Country"
            options={[
              {label: 'United States', value: 'US'},
              {label: 'Peru', value: 'PE'},
              {label: 'Colombia', value: 'CO'},
            ]}
            onChange={(country: string) => {
              setCountry(country);
              getAdmins1(country);
            }}
          />
        </Form.Item>

        <Form.Item
          label="Admin1"
          name="courierAddress.admin1Id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            allowClear
            placeholder="Admin1"
            options={admins1}
            showSearch
            filterOption={(inputValue, option) => {
              if (option && typeof option.label === 'string') {
                return (
                  option.label
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                );
              }

              return false;
            }}
            onChange={(admin1: number) => {
              getAdmins2(`${admin1}`);
            }}
          />
        </Form.Item>

        <Form.Item
          label="Admin2"
          name="courierAddress.admin2Id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            allowClear
            placeholder="Admin2"
            options={admins2}
            disabled={!admins2 || admins2.length === 0}
            showSearch
            filterOption={(inputValue, option) => {
              if (option && typeof option.label === 'string') {
                return (
                  option.label
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                );
              }

              return false;
            }}
            onChange={(admin2: number) => {
              getAdmins3(`${admin2}`);
            }}
          />
        </Form.Item>

        {country !== 'US' && (
          <Form.Item
            label="Admin3"
            name="courierAddress.admin3Id"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              allowClear
              placeholder="Admin3"
              options={admins3}
              disabled={!admins3 || admins3.length === 0}
              showSearch
              filterOption={(inputValue, option) => {
                if (option && typeof option.label === 'string') {
                  return (
                    option.label
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  );
                }

                return false;
              }}
            />
          </Form.Item>
        )}

        <Form.Item
          label="Address1"
          name="courierAddress.address1"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Address2" name="courierAddress.address2">
          <Input />
        </Form.Item>
        <Form.Item
          label="ZipCode"
          name="courierAddress.zipCode"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>,
  ];

  return (
    <Create
      isLoading={queryResult?.isFetching}
      footerButtons={
        <>
          {current > 0 && (
            <Button
              onClick={() => {
                gotoStep(current - 1);
              }}
            >
              Previous
            </Button>
          )}
          {current < formList.length - 1 && (
            <Button
              onClick={() => {
                gotoStep(current + 1);
              }}
            >
              Next
            </Button>
          )}
          {current === formList.length - 1 && (
            <SaveButton style={{marginRight: 10}} {...saveButtonProps} />
          )}
        </>
      }
    >
      <Steps {...stepsProps} responsive>
        <Steps.Step title="Account" />
        <Steps.Step title="Settings" />
      </Steps>

      <Form
        {...formProps}
        style={{marginTop: 30}}
        layout="vertical"
        initialValues={{
          verified: false,
          hasCustoms: true,
          'courierAddress.phoneCountry': 'US',
          'courierAddress.country': 'US',
        }}
      >
        {formList[current]}
      </Form>
    </Create>
  );
};
