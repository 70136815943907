import React, {useCallback, useState} from 'react';
import {IOrder} from '../../../../interfaces';
import {OrderService} from '../../service';
import {axiosInstance} from '@refinedev/simple-rest';
import {AxiosInstance} from 'axios';
import {Button, Card, Divider, Modal, Space, Typography} from 'antd';
import {OrderState, OrderItemsState} from '../../../../layout/components';
import {dateFmt} from '../../../../utils/date';
import {CloseOutlined} from '@ant-design/icons';
import {OrderBeforeDate} from '../order-before-date';

const orderService = new OrderService(axiosInstance as AxiosInstance);

export const OrderInfo: React.FC<{
  order?: IOrder;
  isLoading?: boolean;
  refetch: () => Promise<any>;
}> = ({order, isLoading, refetch}) => {
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [loadingCancel, setLoadingCancel] = useState<boolean>(false);

  const cancelOrder = useCallback(() => {
    if (order) {
      setLoadingCancel(true);
      orderService
        .cancelOrder(order.id)
        .then(() => {
          setLoadingCancel(false);
          refetch().then(() => setCancelModalOpen(false));
        })
        .catch(() => setLoadingCancel(false));
    }
  }, [orderService, order, setLoadingCancel, setCancelModalOpen, refetch]);

  return (
    <>
      <Card>
        <Space
          direction="vertical"
          size="small"
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <Typography.Title
            level={3}
            style={{
              textAlign: 'center',
              margin: '0 -24px 16px',
              paddingRight: '24px',
              paddingLeft: '24px',
            }}
          >
            {order?.id}
          </Typography.Title>
          <Divider style={{margin: '0 0 8px 0'}} />
          <Typography.Text>
            <strong>State:</strong> <OrderState state={order?.state} />
          </Typography.Text>
          <Typography.Text>
            <strong>Items state:</strong> <br />
            <OrderItemsState order={order} />
          </Typography.Text>
          <Typography.Text>
            <strong>Paid at:</strong> {order && dateFmt(new Date(order.paidAt))}
          </Typography.Text>
          <Typography.Text>
            <strong>Before date:</strong>{' '}
            {order && <OrderBeforeDate beforeDate={order.beforeDate} />}
          </Typography.Text>
          <Typography.Text>
            <strong>Purchase country:</strong>{' '}
            {order && order.purchaseCountry.code}
          </Typography.Text>
          <Typography.Text>
            <strong>Delivery country:</strong>{' '}
            {order && order.deliveryCountry.code}
          </Typography.Text>
          <br />
          <Button
            type="primary"
            size="large"
            danger
            block
            hidden={order && !OrderService.canCancelOrder(order)}
            disabled={isLoading}
            onClick={() => setCancelModalOpen(true)}
          >
            <CloseOutlined />
            Cancel order
          </Button>
        </Space>
      </Card>
      <Modal
        open={cancelModalOpen}
        title={<>{`Cancel order ${order ? order.id : ''}`}</>}
        confirmLoading={loadingCancel}
        onOk={() => cancelOrder()}
        onCancel={() => setCancelModalOpen(false)}
      >
        <Typography.Text>
          Are you sure you want to cancel the whole order ?
        </Typography.Text>
      </Modal>
    </>
  );
};
