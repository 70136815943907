import React from 'react';
import {ICourier} from '../../../../interfaces';
import {Grid, Space, Typography} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {Emoji} from 'layout/components';

const {useBreakpoint} = Grid;

export const CourierInfo: React.FC<{
  courier?: ICourier;
}> = ({courier}) => {
  const {xl} = useBreakpoint();

  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
        textAlign: xl ? 'unset' : 'center',
      }}
    >
      <Typography.Text>
        <UserOutlined /> {courier && courier.id}
      </Typography.Text>
      <Typography.Text>
        <UserOutlined /> {courier && courier.email}
      </Typography.Text>
      <Typography.Text>
        {courier && courier.verified ? (
          <Emoji size={18} emoji="✅" />
        ) : (
          <Emoji size={18} emoji="❌" />
        )}{' '}
        {courier && courier.verified ? 'Verified' : 'Not verified'}
      </Typography.Text>
      <Typography.Text>
        {courier &&
        courier.courierSetting &&
        courier.courierSetting.hasCustoms ? (
          <Emoji size={18} emoji="✅" />
        ) : (
          <Emoji size={18} emoji="❌" />
        )}{' '}
        {courier && courier.courierSetting && courier.courierSetting.hasCustoms
          ? 'Has customs'
          : 'No customs'}
      </Typography.Text>
    </Space>
  );
};
