import React, {useCallback, useMemo, useState} from 'react';
import {IOrder, IOrderItem} from '../../../../interfaces';
import {Alert, Button, Image, Modal, Space, Table, Tag, Typography} from 'antd';
import {OrderItemTrackingEdit} from '../../../order-item-tracking/components';
import {Emoji, OrderItemState} from '../../../../layout/components';
import {List, NumberField} from '@refinedev/antd';
import {OrderService} from '../../service';
import {CloseOutlined} from '@ant-design/icons';
import {axiosInstance} from '@refinedev/simple-rest';
import {AxiosInstance} from 'axios';

const orderService = new OrderService(axiosInstance as AxiosInstance);

export const OrderProducts: React.FC<{
  order?: IOrder;
  isLoading?: boolean;
  refetch: () => Promise<any>;
}> = ({order, isLoading, refetch}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [cancelItemsModalOpen, setCancelItemsModalOpen] =
    useState<boolean>(false);
  const [loadingCancel, setLoadingCancel] = useState<boolean>(false);

  const [progressItemsModalOpen, setProgressItemsModalOpen] =
    useState<boolean>(false);
  const [loadingProgress, setLoadingProgress] = useState<boolean>(false);

  const cancelItems = useCallback(() => {
    if (order) {
      setLoadingCancel(true);
      orderService
        .cancelItems(order.id, selectedRowKeys)
        .then(() => {
          setLoadingCancel(false);
          refetch().then(() => {
            setSelectedRowKeys([]);
            setCancelItemsModalOpen(false);
          });
        })
        .catch(() => setLoadingCancel(false));
    }
  }, [
    orderService,
    order,
    setLoadingCancel,
    setCancelItemsModalOpen,
    refetch,
    selectedRowKeys,
  ]);

  const selectedRowStates = useMemo<string[]>(() => {
    if (!order) return [];
    const items: IOrderItem[] = selectedRowKeys
      .map((id) => order.items.find((i) => i.id === id))
      .filter((i) => !!i) as IOrderItem[];

    return items.map((i) => i?.state);
  }, [selectedRowKeys, order]);

  const progressItems = useCallback(() => {
    if (order) {
      setLoadingProgress(true);
      orderService
        .progressItems(
          order.id,
          selectedRowKeys,
          OrderService.nextProgressOrderItemState(
            selectedRowStates[0]
          ) as string
        )
        .then(() => {
          setLoadingProgress(false);
          refetch().then(() => {
            setSelectedRowKeys([]);
            setProgressItemsModalOpen(false);
          });
        })
        .catch(() => setLoadingProgress(false));
    }
  }, [
    order,
    selectedRowKeys,
    selectedRowStates,
    setLoadingProgress,
    setSelectedRowKeys,
    setProgressItemsModalOpen,
  ]);

  const shouldShowCancelItems = useMemo<boolean>(() => {
    if (!order) return false;
    if (selectedRowKeys.length === 0) return false;

    return selectedRowKeys.every((k) => {
      const item = order.items.find((i) => i.id === k);
      return item?.state !== 'CANCELED';
    });
  }, [order, selectedRowKeys]);

  const shouldShowProgressItems = useMemo<boolean>(() => {
    if (!order) return false;
    if (selectedRowKeys.length === 0) return false;

    if (selectedRowStates.length === 0) return false;
    if (new Set(selectedRowStates).size !== 1) return false;

    return !!OrderService.nextProgressOrderItemState(selectedRowStates[0]);
  }, [order, selectedRowStates, selectedRowKeys]);

  return (
    <>
      <List
        title="Products"
        headerButtons={
          <Space style={{gap: 0, marginLeft: '1em'}}>
            <Button
              type="primary"
              size="middle"
              danger
              block
              hidden={!shouldShowCancelItems}
              disabled={isLoading || loadingCancel}
              onClick={() => setCancelItemsModalOpen(true)}
            >
              <CloseOutlined />
              Cancel items ({selectedRowKeys.length})
            </Button>
          </Space>
        }
        headerProps={{
          subTitle: (
            <Space style={{gap: 0, marginLeft: '1em'}}>
              <Button
                type="primary"
                size="middle"
                block
                hidden={!shouldShowProgressItems}
                disabled={isLoading || loadingProgress}
                onClick={() => setProgressItemsModalOpen(true)}
              >
                <Emoji
                  emoji="⏩"
                  size={15}
                  style={{marginRight: 4, marginTop: -1}}
                />
                Progress items ({selectedRowKeys.length})
              </Button>
            </Space>
          ),
        }}
      >
        <Table
          pagination={false}
          dataSource={order?.items}
          rowKey="id"
          size="small"
          scroll={{x: true}}
          expandable={{
            expandedRowRender: (record) =>
              order && (
                <OrderItemTrackingEdit
                  key={record.id}
                  orderId={order.id}
                  orderItemId={record.id}
                />
              ),
          }}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: (newSelectedRowKeys: any[]) => {
              setSelectedRowKeys(newSelectedRowKeys);
            },
          }}
        >
          <Table.Column<IOrderItem>
            key="id"
            title="ID"
            render={(record) => (
              <Typography.Text
                style={{wordBreak: 'inherit', whiteSpace: 'nowrap'}}
              >
                {record.id}
              </Typography.Text>
            )}
          />

          <Table.Column<IOrderItem>
            key="image"
            title="Image"
            align="center"
            render={(record: IOrderItem) => (
              <Image
                width={128}
                height={128}
                src={record.imageUrl || record.image?.publicUrl}
                draggable={false}
                style={{objectFit: 'scale-down'}}
              />
            )}
          />

          <Table.Column<IOrderItem>
            key="category"
            title="Category"
            render={(record: IOrderItem) => <Tag>{record.category?.name}</Tag>}
          />

          <Table.Column<IOrderItem>
            key="name"
            title="Name"
            render={(record: IOrderItem) => (
              <Space direction="vertical" size="middle" style={{width: '100%'}}>
                <Typography.Link
                  href={record.url}
                  style={{display: 'block', minWidth: 250}}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {record.name}
                </Typography.Link>
                {record.detail && (
                  <Alert
                    message={record.detail}
                    type="info"
                    showIcon
                    icon={<Emoji emoji="ℹ️" style={{marginRight: 8}} />}
                  />
                )}
              </Space>
            )}
          />

          <Table.Column<IOrderItem>
            key="price"
            title="Price"
            render={(record: IOrderItem) => (
              <NumberField
                value={record.price}
                options={{
                  style: 'currency',
                  currency: 'USD',
                }}
                style={{
                  whiteSpace: 'nowrap',
                  display: 'block',
                }}
              />
            )}
          />

          <Table.Column<IOrderItem>
            key="weight"
            title="Weight"
            render={(record: IOrderItem) => (
              <Typography.Text
                style={{wordBreak: 'inherit', whiteSpace: 'nowrap'}}
              >
                {record.weight} Kg
              </Typography.Text>
            )}
          />
          <Table.Column<IOrderItem>
            key="quantity"
            title="Quantity"
            align="center"
            render={(record) => (
              <Space>
                <Typography.Text style={{wordBreak: 'inherit'}}>
                  {record.quantity}
                </Typography.Text>
              </Space>
            )}
          />
          <Table.Column<IOrderItem>
            key="state"
            title="State"
            fixed="right"
            render={(record) => (
              <Space>
                <OrderItemState state={record.state} />
              </Space>
            )}
          />
        </Table>
      </List>
      <Modal
        open={cancelItemsModalOpen}
        title={`Cancel ${selectedRowKeys.length} item(s)`}
        confirmLoading={loadingCancel}
        onOk={() => cancelItems()}
        onCancel={() => setCancelItemsModalOpen(false)}
      >
        <Typography.Text style={{display: 'block'}}>
          Are you sure you want to cancel the following item(s) ?
        </Typography.Text>
        <Typography.Text style={{display: 'block', marginTop: 8}}>
          <ul>
            {selectedRowKeys.map((k) => (
              <li key={k}>{k}</li>
            ))}
          </ul>
        </Typography.Text>
      </Modal>
      <Modal
        open={progressItemsModalOpen}
        title={<>{`Progress ${selectedRowKeys.length} item(s)`}</>}
        confirmLoading={loadingProgress}
        onOk={() => progressItems()}
        onCancel={() => setProgressItemsModalOpen(false)}
      >
        <Typography.Text style={{display: 'block'}}>
          Are you sure you want to progress the following item(s) ?
        </Typography.Text>
        <Typography.Text style={{display: 'block', marginTop: 8}}>
          <ul>
            {selectedRowKeys.map((k) => (
              <li key={k}>{k}</li>
            ))}
          </ul>
        </Typography.Text>
        <Typography.Text style={{display: 'block', marginTop: 8}}>
          Items are going to progress from{' '}
          <OrderItemState state={selectedRowStates[0]} /> to{' '}
          <OrderItemState
            state={OrderService.nextProgressOrderItemState(
              selectedRowStates[0]
            )}
          />
        </Typography.Text>
      </Modal>
    </>
  );
};
