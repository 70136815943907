import {TOKEN_KEY} from '../../authProvider';
import {AxiosInstance} from 'axios';
import {vars} from '../../vars';
import { IUser } from 'interfaces';

export type SaveUserRequest = {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  altUserId?: string;
  password?: string;
  rawPassword?: boolean;
};

export class UserService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  public async getUsers(page: number, size: number, userId?: string, email?: string) {
    const query = new URLSearchParams();
    query.set('page', `${page}`);
    query.set('size', `${size}`);
    if (userId) query.set('user_id', userId);
    if (email) query.set('email', email);

    const url = `${vars.baseApiUrl}/usher/admin/auth/user/list`;
    const {data} = await this.httpClient.get(`${url}?${query.toString()}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });
    return data;
  }

  public async getUser(userId: string) {
    const url = `${vars.baseApiUrl}/usher/admin/auth/user/${userId}`;

    try {
      const response = await this.httpClient.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      });
      return response.data;
    } catch (error) {
      throw Error('Error getting user information by ID');
    }
  }

  public async saveUser(request: SaveUserRequest) {
    let user: IUser;
    const body: SaveUserRequest = {
      firstName: request.firstName,
      lastName: request.lastName,
      email: request.email,
      rawPassword: false,
    };

    if (request.altUserId) {
      body.altUserId = request.altUserId;
    }

    if (request.password) {
      body.password = request.password;
    }

    if (!request.id) {
      const url = `${vars.baseApiUrl}/usher/admin/auth/user/`;

      const {data} = await this.httpClient.post(
        url,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
          },
        }
      );

      user = data;
    } else {
      const url = `${vars.baseApiUrl}/usher/admin/auth/user/${request.id}`;

      const {data} = await this.httpClient.post(
        url,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
          },
        }
      );

      user = data;
    }

    return user;
  }
}
