import {DataProvider} from '@refinedev/core';
import {AxiosInstance} from 'axios';
import {axiosInstance} from '@refinedev/simple-rest';
import {OrderItemService} from './service';

export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance as AxiosInstance
): Omit<
  Required<DataProvider>,
  | 'getList'
  | 'update'
  | 'create'
  | 'deleteOne'
  | 'getMany'
  | 'createMany'
  | 'updateMany'
  | 'deleteMany'
  | 'custom'
> => {
  const orderItemService = new OrderItemService(httpClient);

  return {
    getOne: async ({id}) => {
      const orderItem = await orderItemService.getOrderItem(id as string);

      return {
        data: orderItem,
      };
    },
    getApiUrl: () => apiUrl,
  };
};
