import {AxiosInstance} from 'axios';
import {vars} from '../../vars';
export class CategoryService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  public async getCategories() {
    const url = `${vars.baseApiUrl}/logistics/category`;
    const {data} = await this.httpClient.get(`${url}`);

    return data;
  }
}
