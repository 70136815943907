import React, {useMemo, useState} from 'react';
import {Button, Card, Col, Divider, Row, Typography} from 'antd';
import {IOrder} from '../../../../interfaces';
import {Emoji, ShowAddress} from '../../../../layout/components';
import {CourierInfo} from '../../../courier/components/courier-info';
import {OrderAssignLogisticModal} from '../order-assign-logistic-modal';

export const OrderLogistic: React.FC<{
  order: IOrder;
  refetch: () => Promise<any>;
}> = ({order, refetch}) => {
  const title = useMemo(() => {
    let t = null;
    if (order.flight && order.courierId)
      t = (
        <>
          Traveler & Courier <Emoji size={21} emoji="✈️" />{' '}
          <Emoji size={21} emoji="📦" />
        </>
      );
    else if (order.logisticType === 'FLIGHT')
      t = (
        <>
          <Emoji size={18} emoji="✈️" style={{marginRight: 4}} /> Traveler:{' '}
        </>
      );
    else if (order.logisticType === 'COURIER')
      t = (
        <>
          <Emoji size={18} emoji="📦" style={{marginRight: 4}} /> Courier:{' '}
          {order.shipmentAddress.name}
        </>
      );

    return t;
  }, [order]);
  const [openAssignModal, setOpenAssignModal] = useState(false);

  const assignLogistic = useMemo(() => {
    if (!order.logisticType) {
      return (
        <>
          <Button
            type="primary"
            size="large"
            onClick={() => setOpenAssignModal(true)}
          >
            Assign Logistic Type &nbsp;
            <Emoji emoji="📥" />
          </Button>
          <OrderAssignLogisticModal
            order={order}
            open={openAssignModal}
            onOk={() => {
              refetch().then(() => setOpenAssignModal(false));
            }}
            onCancel={() => setOpenAssignModal(false)}
          />
        </>
      );
    }

    return null;
  }, [order, openAssignModal, setOpenAssignModal]);

  const courierInfo = useMemo(() => {
    if (order.courierId && order.shipmentAddress) {
      return (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Typography.Title level={4}>Shipment Address</Typography.Title>
            <Divider style={{margin: '0 0 8px 0'}} />
            <ShowAddress address={order.shipmentAddress} />
          </Col>
          <Col span={12}>
            <Typography.Title level={4}>
              {order.shipmentAddress.name}
            </Typography.Title>
            <Divider style={{margin: '0 0 8px 0'}} />
            <CourierInfo courier={order.courier} />
          </Col>
        </Row>
      );
    }

    return null;
  }, [order]);

  return (
    <Card
      style={{minHeight: '100%'}}
      title={
        title && (
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <span>{title}</span>
          </div>
        )
      }
    >
      {assignLogistic}
      {courierInfo}
    </Card>
  );
};
