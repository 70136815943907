import React from 'react';
import {AuthPage as AntdAuthPage, AuthProps} from '@refinedev/antd';
import {Link} from 'react-router-dom';

const authWrapperProps = {
  style: {
    background:
      'radial-gradient(50% 50% at 50% 50%,rgba(255, 255, 255, 0) 0%,' +
      'rgba(0, 0, 0, 0.85) 100%),' +
      'url("https://pbs.twimg.com/media/EUjK4t6UYAAIJRm?format=jpg&name=large")',
    backgroundSize: 'cover',
  },
};

const renderAuthContent = (content: React.ReactNode) => {
  return (
    <div
      style={{
        maxWidth: 408,
        margin: 'auto',
      }}
    >
      <Link to="/" style={{display: 'block', textAlign: 'center'}}>
        <img
          style={{marginBottom: 26, filter: 'brightness(0) invert(1)'}}
          src="https://s3.amazonaws.com/assets.dev.qempo.io/browserconfig/logo.svg"
          alt="Qempo"
          width="180px"
        />
      </Link>
      {content}
    </div>
  );
};

export const AuthPage: React.FC<AuthProps> = (props) => {
  return (
    <AntdAuthPage
      wrapperProps={authWrapperProps}
      renderContent={renderAuthContent}
      {...props}
    />
  );
};
