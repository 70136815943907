import {DataProvider} from '@refinedev/core';
import {AxiosInstance} from 'axios';
import {axiosInstance} from '@refinedev/simple-rest';
import {CategoryService} from './service';

export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance as AxiosInstance
): Omit<
  Required<DataProvider>,
  | 'create'
  | 'update'
  | 'deleteOne'
  | 'getMany'
  | 'getOne'
  | 'createMany'
  | 'updateMany'
  | 'deleteMany'
  | 'custom'
> => {
  const categoryService = new CategoryService(httpClient);

  return {
    getList: async () => {
      const categoriesPage = await categoryService.getCategories();

      return {
        data: categoriesPage,
        total: categoriesPage.length,
      };
    },
    getApiUrl: () => apiUrl,
  };
};
