import React from 'react';
import {Tag} from 'antd';

export const OrderState: React.FC<{state?: string; suffix?: string}> = ({
  state,
}) => {
  let color = 'cyan';

  if (state === 'PAID') color = 'blue';
  if (state === 'COMPLETED') color = 'green';
  if (state === 'CANCELED') color = 'red';

  return <Tag color={color}>{state}</Tag>;
};
