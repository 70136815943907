import {DataProvider} from '@refinedev/core';
import {AxiosInstance} from 'axios';

import {axiosInstance} from '@refinedev/simple-rest';
import {OrderItemTrackingService, RegisterItemsRequest} from './service';
import {IOrderItem} from '../../interfaces';

export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance as AxiosInstance
): Omit<
  Required<DataProvider>,
  | 'getList'
  | 'create'
  | 'deleteOne'
  | 'getMany'
  | 'createMany'
  | 'updateMany'
  | 'deleteMany'
  | 'custom'
> => {
  const orderItemTracking = new OrderItemTrackingService(httpClient);

  return {
    getOne: async ({id}) => {
      const orderItem = await orderItemTracking.getOrderItem(id as string);

      return {
        data: orderItem.itemTracking,
      };
    },
    update: async ({variables, meta}) => {
      const {orderItemId, orderId} = meta || {};
      const {orderNumber, trackingNumber, invoiceFileUrl, invoiceFile} =
        variables as RegisterItemsRequest;

      const itemRequest: RegisterItemsRequest = {
        orderItemId,
        orderNumber,
        trackingNumber,
        invoiceFileUrl,
        invoiceFile,
      };

      const order = await orderItemTracking.registerOrder(orderId, [
        itemRequest,
      ]);

      const orderItem = order.items.find(
        (i: IOrderItem) => i.id === orderItemId
      );

      return {
        data: orderItem?.itemTracking,
      };
    },
    /*getList: async () => {
      const couriers = await courierService.getCouriers();

      return {
        data: couriers,
        total: couriers.length,
      };
    },
    create: async ({variables}) => {
      const {email, password, verified, courierAddress} = unflatten<
        any,
        Record<string, any>
      >(variables);

      const courier = await courierService.saveCourier({
        email,
        password,
        verified,
        courierAddress,
      });

      return {
        data: courier,
      };
    },
    */
    getApiUrl: () => apiUrl,
  };
};
