type Environment = 'local' | 'dev' | 'production';

interface Vars {
  environment: Environment;
  baseApiUrl: string;
}

const vars: Vars = {
  environment: (process.env.REACT_APP_ENVIRONMENT as Environment) || 'local',
  baseApiUrl:
    (process.env.REACT_APP_BASE_API_URL as string) ||
    'http://local.qempo.io/api',
};

console.log(vars);

export {vars};
