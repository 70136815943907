import React, {useMemo} from 'react';
import {Tag} from 'antd';
import {IOrder} from '../../../interfaces';

export const OrderItemState: React.FC<{
  state?: string;
  suffix?: string;
  style?: React.CSSProperties;
}> = ({state, suffix, style = {}}) => {
  let color = 'cyan';

  if (state === 'ITEM_CREATED') color = 'cyan';
  if (state === 'PURCHASED') color = 'blue';
  if (state === 'RECEIVED') color = 'geekblue';
  if (state === 'ARRIVED') color = 'orange';
  if (state === 'DELIVERED') color = 'lime';
  if (state === 'COMPLETED') color = 'green';
  if (state === 'CANCELED') color = 'red';

  return (
    <Tag color={color} style={{margin: 4, ...style}}>
      {state}
      {suffix}
    </Tag>
  );
};

export const OrderItemsState: React.FC<{order?: IOrder}> = ({order}) => {
  const statesCount = useMemo<Record<string, number>>(() => {
    const statesCount: Record<string, number> = {};

    if (order) {
      order.items.forEach((i) => {
        if (!statesCount[i.state]) statesCount[i.state] = 1;
        else statesCount[i.state]++;
      });
    }
    return statesCount;
  }, [order]);

  return (
    <>
      {statesCount &&
        Object.keys(statesCount).map((state) => (
          <OrderItemState
            key={state}
            state={state}
            suffix={` x${statesCount[state]}`}
          />
        ))}
    </>
  );
};
