import React, {useState} from 'react';
import {IResourceComponentsProps, useShow} from '@refinedev/core';
import {Alert, Card, Col, Row, Space, Tabs, Typography} from 'antd';
import {ICourier} from '../../../interfaces';
import {Show} from '@refinedev/antd';
import {Emoji, ShowAddress} from '../../../layout/components';
import {CourierInfo} from '../components/courier-info';
import {useParams} from 'react-router-dom';
import {PricingCommission} from '../../pricing/components';
import {CourierCategorySetting} from '../components/courier-category-setting';

export const CourierShow: React.FC<IResourceComponentsProps> = () => {
  const {id} = useParams();
  const {queryResult: courierQueryResult} = useShow<ICourier>({
    resource: 'couriers',
    id,
  });

  const {isLoading, data, refetch} = courierQueryResult;

  const [tab, setTab] = useState<string>('PRICING');

  const courier = data?.data as ICourier;

  const tabItems = [
    {
      label: 'Pricing',
      key: 'pricing',
      children: (
        <>
          {courier && courier.courierSetting && (
            <PricingCommission
              courier={courier}
              litePricingCommission={
                courier.courierSetting.litePricingCommission
              }
              fullPricingCommission={
                courier.courierSetting.fullPricingCommission
              }
              onAfterSave={() => refetch()}
              onAfterEnable={() => refetch()}
            />
          )}
        </>
      ),
    },
    {
      label: 'Delivery Days',
      key: 'delivery-days',
      children: (
        <>
          {courier && courier.courierSetting && (
            <CourierCategorySetting
              courier={courier}
              liteCourierCategorySetting={
                courier.courierSetting.liteCourierCategorySetting
              }
              fullCourierCategorySetting={
                courier.courierSetting.fullCourierCategorySetting
              }
              onAfterSave={() => refetch()}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <Show isLoading={isLoading}>
      <Space direction="vertical" size={48} style={{width: '100%'}}>
        <Row gutter={[16, 16]}>
          <Col xl={8} lg={24} xs={24}>
            <Card
              bordered
              style={{
                height: '100%',
              }}
            >
              <Space
                direction="vertical"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <Space
                  direction="vertical"
                  style={{
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  <Typography.Title
                    level={3}
                    style={{
                      marginRight: '-24px',
                      marginLeft: '-24px',
                      paddingBottom: '24px',
                      borderBottom: '1px solid #f0f0f0',
                      paddingRight: '24px',
                      paddingLeft: '24px',
                    }}
                  >
                    Courier:{' '}
                    {courier &&
                    courier.courierSetting &&
                    courier.courierSetting.courierAddress
                      ? courier.courierSetting.courierAddress.name
                      : ''}
                  </Typography.Title>
                </Space>
                <CourierInfo courier={courier} />
              </Space>
            </Card>
          </Col>
          {courier && courier.courierSetting && (
            <Col xl={16} xs={24}>
              <Card
                title={`Address: ${
                  courier.courierSetting.courierAddress
                    ? courier.courierSetting.courierAddress.id
                    : ''
                }`}
                bordered
                style={{
                  height: '100%',
                }}
              >
                <Space
                  direction="vertical"
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {!courier.courierSetting.courierAddress && (
                    <Alert
                      message="No address was configured"
                      type="warning"
                      showIcon
                      style={{marginTop: '-4px'}}
                      icon={<Emoji emoji="⚠️️" style={{marginRight: 8}} />}
                    />
                  )}
                  {courier.courierSetting.courierAddress && (
                    <ShowAddress
                      address={courier.courierSetting.courierAddress}
                    />
                  )}
                </Space>
              </Card>
            </Col>
          )}
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Tabs
              type="card"
              tabPosition="top"
              items={tabItems}
              defaultActiveKey={tab}
              onChange={(t) => setTab(t)}
            />
          </Col>
        </Row>
      </Space>
    </Show>
  );
};
