import React from 'react';
import {Tag} from 'antd';

export const InvoiceLineType: React.FC<{type?: string}> = ({type}) => {
  let color = 'default';

  if (type) {
    if (
      [
        'ITEM',
        'STORE_DELIVERY',
        'TAX_RESERVE',
        'CUSTOMS_DUTIES',
        'COMMISSION',
        'QEMPO',
        'WARRANTY',
        'DELIVERY',
      ].includes(type)
    ) {
      color = 'default';
    }

    if (
      [
        'ITEM_DEDUCTION',
        'STORE_DELIVERY_DEDUCTION',
        'TAX_RESERVE_DEDUCTION',
        'CUSTOMS_DUTIES_DEDUCTION',
        'COMMISSION_DEDUCTION',
        'QEMPO_DEDUCTION',
        'WARRANTY_DEDUCTION',
        'DELIVERY_DEDUCTION',
        'COUPON',
        'COUPON_DEDUCTION',
      ].includes(type)
    ) {
      color = 'red';
    }

    if (
      [
        'ITEM_DISPUTE_SETTLEMENT',
        'STORE_DELIVERY_DISPUTE_SETTLEMENT',
        'TAX_RESERVE_DISPUTE_SETTLEMENT',
        'CUSTOMS_DUTIES_DISPUTE_SETTLEMENT',
        'COMMISSION_DISPUTE_SETTLEMENT',
        'QEMPO_DISPUTE_SETTLEMENT',
        'WARRANTY_DISPUTE_SETTLEMENT',
        'DELIVERY_DISPUTE_SETTLEMENT',
        'COUPON_DISPUTE_SETTLEMENT',
      ].includes(type)
    ) {
      color = 'blue';
    }
  }

  return <Tag color={color}>{type}</Tag>;
};
