import React, {useMemo} from 'react';
import {IOrderItem} from '../../../../interfaces';
import {useOne} from '@refinedev/core';
import {Col, Image, Row, Space, Spin, Tag, Typography} from 'antd';
import {Emoji, OrderItemState} from '../../../../layout/components';
import {NumberField, ShowButton} from '@refinedev/antd';

const {Text} = Typography;

type Props = {
  orderItemId: string;
  orderId: string;
};

export const OrderItem: React.FC<Props> = ({orderItemId, orderId}) => {
  const {data, isLoading, isFetching, isRefetching} = useOne<IOrderItem>({
    resource: 'orderItems',
    id: orderItemId,
    meta: {
      orderItemId,
      orderId,
    },
  });

  const orderItem = data?.data;

  const loading = useMemo<boolean>(() => {
    return !!(isLoading && isFetching && isRefetching && orderItem);
  }, [isLoading, isFetching, isRefetching, orderItem]);

  return (
    <Row gutter={[16, 16]} style={{padding: 16}}>
      {loading && (
        <Col xs={24} style={{textAlign: 'center', padding: 24}}>
          <Spin />
        </Col>
      )}
      {orderItem && !loading && (
        <>
          <Col xl={4} lg={4} xs={24}>
            <Image
              width={128}
              height={128}
              src={orderItem.imageUrl || orderItem.image?.publicUrl}
              draggable={false}
              style={{objectFit: 'scale-down'}}
            />
          </Col>
          <Col xl={20} lg={20} xs={24}>
            <Space direction="vertical">
              <Text>
                <Space>
                  <Text strong>Order ID: </Text>
                  <Text>{orderId}</Text>
                  <ShowButton
                    icon={<Emoji emoji="👀" />}
                    hideText
                    size="middle"
                    resource="orders"
                    recordItemId={orderId}
                  />
                </Space>
              </Text>
              <Text>
                <Text strong>Order Item ID: </Text>
                {orderItem.id}
              </Text>
              <Text>
                <Text strong>Name: </Text>
                <Typography.Link
                  href={orderItem.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {orderItem.name}
                </Typography.Link>
              </Text>
              <Text>
                <Text strong>Price: </Text>
                <NumberField
                  value={orderItem.price}
                  options={{
                    style: 'currency',
                    currency: 'USD',
                  }}
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                />
              </Text>
              <Text>
                <Text strong>Weight: </Text>
                {orderItem.weight} Kg
              </Text>
              <Text>
                <Text strong>Quantity: </Text>
                {orderItem.quantity}
              </Text>
              <Text>
                <Text strong>Category: </Text>
                <Tag>{orderItem.category.name}</Tag>
              </Text>
              <Text>
                <Text strong>State: </Text>
                <OrderItemState state={orderItem.state} />
              </Text>
            </Space>
          </Col>
        </>
      )}
    </Row>
  );

  /*return (
    <div>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );*/
};
