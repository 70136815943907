export type JwtToken = {
  header: {alg: string; [key: string]: any};
  payload: {
    iss: string;
    sub: string;
    jti: string;
    iat: number;
    exp: number;
    [key: string]: any;
  };
  signature: string;
};

export class JwtUtil {
  public static parseToken(token: string): JwtToken {
    const parts = token.split('.');
    const header = JSON.parse(atob(parts[0]));
    const payload = JSON.parse(atob(parts[1]));
    return {
      header,
      payload,
      signature: parts[2],
    } as JwtToken;
  }
  public static validateExp(token: string): boolean {
    const jwtToken = JwtUtil.parseToken(token);
    const secondsSinceEpoch = Math.floor(Date.now() / 1000);
    return secondsSinceEpoch < jwtToken.payload.exp;
  }
}
