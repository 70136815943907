import React from 'react';
import {Typography} from 'antd';

type EmojiProps = {
  size?: number;
  emoji: string;
  style?: React.CSSProperties;
};

export const Emoji: React.FC<EmojiProps> = ({
  size: fontSize = 14,
  emoji,
  style = {},
}) => {
  return (
    <Typography.Text
      style={{
        fontSize,
        fontFamily:
          'apple color emoji, segoe ui emoji, noto color emoji, android emoji, emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol',
        verticalAlign: 'middle',
        ...style,
      }}
    >
      {emoji}
    </Typography.Text>
  );
};
