import React, {useState} from 'react';
import {Alert, Tabs} from 'antd';
import {useList} from '@refinedev/core';
import {List} from '@refinedev/antd';
import {PricingFormulas} from '../pricing-formulas';
import {
  ICategory,
  ICourier,
  IOrderServiceType,
  IPricingCommission,
} from '../../../../interfaces';
import {useMediaQuery} from '../../../../utils/react';
import {Emoji} from '../../../../layout/components';

export const PricingCommission: React.FC<{
  hideLite?: boolean;
  hideFull?: boolean;
  courier?: ICourier;
  litePricingCommission?: IPricingCommission;
  fullPricingCommission?: IPricingCommission;
  onAfterSave: () => void;
  onAfterEnable: () => void;
}> = ({
  hideLite,
  hideFull,
  courier,
  litePricingCommission,
  fullPricingCommission,
  onAfterSave,
  onAfterEnable,
}) => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [tab, setTab] = useState<IOrderServiceType>(
    !hideLite ? 'LITE' : 'FULL'
  );
  const {data} = useList<ICategory>({
    resource: 'categories',
  });

  const {data: categories} = data || {};

  const tabItems = [];

  if (!hideLite) {
    tabItems.push({
      label: 'Lite',
      key: 'LITE',
      children: categories && courier && courier.courierSetting && (
        <PricingFormulas
          categories={categories}
          courier={courier}
          orderServiceType="LITE"
          pricingCommission={litePricingCommission}
          onAfterSave={onAfterSave}
          onAfterEnable={onAfterEnable}
        />
      ),
    });
  }

  if (!hideFull) {
    tabItems.push({
      label: 'Full',
      key: 'FULL',
      children: categories && courier && courier.courierSetting && (
        <PricingFormulas
          categories={categories}
          courier={courier}
          orderServiceType="FULL"
          pricingCommission={fullPricingCommission}
          onAfterSave={onAfterSave}
          onAfterEnable={onAfterEnable}
        />
      ),
    });
  }

  return (
    <List title={`Pricing ${tab}`} breadcrumb={null} canCreate={false}>
      {litePricingCommission &&
        fullPricingCommission &&
        litePricingCommission.id === fullPricingCommission.id && (
          <>
            <Alert
              message="Both FULL and LITE has the same id (if you change one, both change)"
              type="info"
              showIcon
              style={{marginTop: '-4px'}}
              icon={<Emoji emoji="ℹ️" style={{marginRight: 8}} />}
            />
            <br />
          </>
        )}
      <Tabs
        tabPosition={isMobile ? 'top' : 'left'}
        items={tabItems}
        defaultActiveKey={tab}
        onChange={(t) => setTab(t as IOrderServiceType)}
      />
    </List>
  );
};
