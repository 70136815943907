import React, {useCallback, useEffect, useState} from 'react';
import {IResourceComponentsProps} from '@refinedev/core';
import {
  Card,
  Col,
  Row,
  Space,
  Typography,
  Grid,
  Form,
  Input,
  Radio,
  Select,
} from 'antd';
import {IAdmin1, IAdmin2, IAdmin3, ICourier} from '../../../interfaces';
import {Edit, useForm} from '@refinedev/antd';
import {axiosInstance} from '@refinedev/simple-rest';
import {GeographyService} from '../../geography/service';
import {AxiosInstance} from 'axios';
import {
  getAdmin1Label,
  getAdmin2Label,
  getAdmin3Label,
} from '../../../layout/components';
import {useParams} from 'react-router-dom';

const {useBreakpoint} = Grid;

const geographyService = new GeographyService(axiosInstance as AxiosInstance);

export const CourierEdit: React.FC<IResourceComponentsProps> = () => {
  const {id} = useParams();

  const {
    formProps,
    saveButtonProps,
    queryResult: courierQueryResult,
  } = useForm<ICourier>({
    resource: 'couriers',
    id,
  });

  const {xl} = useBreakpoint();

  const {isLoading, isFetching, isRefetching, data} = courierQueryResult || {};

  const courier = data?.data as ICourier;

  const [country, setCountry] = useState<string>();
  const [admins1, setAdmins1] = useState<{label: string; value: string}[]>();
  const [admins2, setAdmins2] = useState<{label: string; value: string}[]>();
  const [admins3, setAdmins3] = useState<{label: string; value: string}[]>();

  const getAdmins1 = useCallback(
    (country: string) => {
      setAdmins1([]);
      setAdmins2([]);
      setAdmins3([]);
      geographyService.getAdmin1(country).then((admins1) => {
        setAdmins1(
          admins1.map((admin1: IAdmin1) => ({
            label: admin1.name,
            value: admin1.id,
          }))
        );
      });
    },
    [geographyService]
  );

  const getAdmins2 = useCallback(
    (admin1: string) => {
      setAdmins2([]);
      setAdmins3([]);
      geographyService.getAdmin2(admin1).then((admins2) => {
        setAdmins2(
          admins2.map((admin2: IAdmin2) => ({
            label: admin2.name,
            value: admin2.id,
          }))
        );
      });
    },
    [geographyService]
  );

  const getAdmins3 = useCallback(
    (admin2: string) => {
      geographyService.getAdmin3(admin2).then((admins2) =>
        setAdmins3(
          admins2.map((admin3: IAdmin3) => ({
            label: admin3.name,
            value: admin3.id,
          }))
        )
      );
    },
    [geographyService]
  );

  useEffect(() => {
    if (courier && courier.courierSetting && !country) {
      setCountry(courier.courierSetting.courierAddress.country.code);
      getAdmins1(courier.courierSetting.courierAddress.country.code);

      setTimeout(() => {
        if (courier.courierSetting)
          getAdmins2(`${courier.courierSetting.courierAddress.admin1.id}`);
      }, 500);

      getAdmins3(`${courier.courierSetting.courierAddress.admin2.id}`);
    }
  }, [courier, country, setCountry, getAdmins1]);

  return (
    <Edit
      isLoading={isLoading || isFetching || isRefetching}
      saveButtonProps={saveButtonProps}
    >
      {courier && (
        <Form
          {...formProps}
          style={{marginTop: 30}}
          layout="vertical"
          initialValues={{
            hasCustoms: courier.courierSetting?.hasCustoms,
            verified: courier.verified,
            email: courier.email,
            'courierAddress.name': courier.courierSetting?.courierAddress?.name,
            'courierAddress.phoneCountry':
              courier.courierSetting?.courierAddress?.phoneCountry.code,
            'courierAddress.phone':
              courier.courierSetting?.courierAddress?.phone,
            'courierAddress.country':
              courier.courierSetting?.courierAddress?.country.code,
            'courierAddress.admin1Id':
              courier.courierSetting?.courierAddress?.admin1.id,
            'courierAddress.admin2Id':
              courier.courierSetting?.courierAddress?.admin2.id,
            'courierAddress.address1':
              courier.courierSetting?.courierAddress?.address1,
            'courierAddress.address2':
              courier.courierSetting?.courierAddress?.address2,
            'courierAddress.zipCode':
              courier.courierSetting?.courierAddress?.zipCode,
          }}
          disabled={isLoading || isFetching || isRefetching}
        >
          <Row gutter={[16, 16]}>
            <Col xl={8} lg={24} xs={24}>
              <Card
                bordered
                style={{
                  height: '100%',
                }}
              >
                <Space
                  direction="vertical"
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <Typography.Title level={4}>
                    {courier && courier.id}
                  </Typography.Title>
                  <Space
                    direction="vertical"
                    style={{
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    <Form.Item
                      label="Name"
                      name="courierAddress.name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Space>
                  <Space
                    direction="vertical"
                    style={{
                      width: '100%',
                      textAlign: xl ? 'unset' : 'center',
                    }}
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          type: 'email',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item label="Password" name="password">
                      <Input.Password />
                    </Form.Item>
                    <Form.Item label="Verified" name="verified">
                      <Radio.Group>
                        <Radio value={true}>Verified</Radio>
                        <Radio value={false}>Not Verified</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Has Customs" name="hasCustoms">
                      <Radio.Group>
                        <Radio value={true}>Has Customs</Radio>
                        <Radio value={false}>Not</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Space>
                </Space>
              </Card>
            </Col>
            <Col xl={16} xs={24}>
              <Card
                bordered
                style={{
                  height: '100%',
                }}
              >
                <Space
                  direction="vertical"
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <Space
                    direction="vertical"
                    style={{
                      textAlign: xl ? 'unset' : 'center',
                      width: '100%',
                    }}
                  >
                    <Typography.Title level={4}>
                      Address ({courier?.courierSetting?.courierAddress?.id})
                    </Typography.Title>
                  </Space>
                  <Space
                    direction="vertical"
                    style={{
                      width: '100%',
                      textAlign: xl ? 'unset' : 'center',
                    }}
                  >
                    <Form.Item
                      label="Phone country"
                      name="courierAddress.phoneCountry"
                      rules={[{required: true}]}
                    >
                      <Select
                        allowClear
                        placeholder="Phone Country"
                        options={[
                          {label: 'United States', value: 'US'},
                          {label: 'Peru', value: 'PE'},
                          {label: 'Colombia', value: 'CO'},
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Phone"
                      name="courierAddress.phone"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Country"
                      name="courierAddress.country"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        placeholder="Country"
                        options={[
                          {label: 'United States', value: 'US'},
                          {label: 'Peru', value: 'PE'},
                          {label: 'Colombia', value: 'CO'},
                        ]}
                        onChange={(country: string) => {
                          setCountry(country);
                          getAdmins1(country);
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={getAdmin1Label(country)}
                      name="courierAddress.admin1Id"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        placeholder="Admin1"
                        options={admins1}
                        showSearch
                        filterOption={(inputValue, option) => {
                          if (option && typeof option.label === 'string') {
                            return (
                              option.label
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0
                            );
                          }

                          return false;
                        }}
                        onChange={(admin1: number) => {
                          getAdmins2(`${admin1}`);
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label={getAdmin2Label(country)}
                      name="courierAddress.admin2Id"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        placeholder="Admin2"
                        options={admins2}
                        disabled={!admins2 || admins2.length === 0}
                        showSearch
                        filterOption={(inputValue, option) => {
                          if (option && typeof option.label === 'string') {
                            return (
                              option.label
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0
                            );
                          }

                          return false;
                        }}
                        onChange={(admin2: number) => {
                          getAdmins3(`${admin2}`);
                        }}
                      />
                    </Form.Item>

                    {country !== 'US' && (
                      <Form.Item
                        label={getAdmin3Label(country)}
                        name="courierAddress.admin3Id"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          placeholder="Admin3"
                          options={admins3}
                          disabled={!admins3 || admins3.length === 0}
                          showSearch
                          filterOption={(inputValue, option) => {
                            if (option && typeof option.label === 'string') {
                              return (
                                option.label
                                  .toLowerCase()
                                  .indexOf(inputValue.toLowerCase()) >= 0
                              );
                            }

                            return false;
                          }}
                        />
                      </Form.Item>
                    )}

                    <Form.Item
                      label="Address1"
                      name="courierAddress.address1"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item label="Address2" name="courierAddress.address2">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="ZipCode"
                      name="courierAddress.zipCode"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Space>
                </Space>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </Edit>
  );
};
