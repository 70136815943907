import React, {useContext, useEffect, useMemo, useState} from 'react';
import {javascript, javascriptLanguage} from '@codemirror/lang-javascript';
import {cobalt, dracula} from 'thememirror';
import CodeMirror from '@uiw/react-codemirror';
import {CompletionContext} from '@codemirror/autocomplete';
import {ColorModeContext} from '../../../../layout/contexts/color-mode';
import {Alert, Divider, Form, InputNumber, Typography} from 'antd';
import {evalWithParams} from '../../../../utils/react';
import {Emoji} from 'layout/components';

function commonVariablesCompletion(context: CompletionContext) {
  const word = context.matchBefore(/\w*/);
  if (!word) return null;
  if (word.from === word.to && !context.explicit) return null;
  return {
    from: word.from,
    options: [
      {
        label: 'item_price',
        type: 'variable',
        info: 'Price of 1 item in dollars ($)',
      },
      {
        label: 'item_weight_kg',
        type: 'variable',
        info: 'Weight of 1 item in kilograms',
      },
      {
        label: 'item_weight_lbs',
        type: 'variable',
        info: 'Weight of 1 item in pounds',
      },
    ],
  };
}

export const FormulaEditor: React.FC<{
  value?: string;
  setValue: (value: string) => void;
  isSaving?: boolean;
  disabled?: boolean;
}> = ({value, setValue, isSaving, disabled}) => {
  const {mode} = useContext(ColorModeContext);

  return (
    <>
      {disabled && (
        <>
          <Alert
            message="This formula is disabled"
            type="error"
            showIcon
            style={{marginTop: '-4px'}}
            icon={<Emoji emoji="❌️" style={{marginRight: 8}} />}
          />
          <br />
        </>
      )}

      <div
        style={{
          opacity: isSaving || disabled ? 0.6 : 1,
          pointerEvents: isSaving || disabled ? 'none' : 'unset',
        }}
      >
        <CodeMirror
          value={value}
          width="100%"
          height="250px"
          extensions={[
            javascript({}),
            javascriptLanguage.data.of({
              autocomplete: commonVariablesCompletion,
            }),
          ]}
          onChange={setValue}
          theme={mode === 'light' ? dracula : cobalt}
          basicSetup={{autocompletion: true}}
          readOnly={disabled}
        />
      </div>
    </>
  );
};

export const FormulaTester: React.FC<{
  name: string;
  formula?: string;
  isSaving?: boolean;
  disabled?: boolean;
}> = ({name, formula, isSaving, disabled}) => {
  const [form] = Form.useForm();
  const [error, setError] = useState<string | undefined>();

  const [price, setPrice] = useState<number | null>(1.52);
  const [weight, setWeight] = useState<number | null>(1.52);

  const weightLbs = useMemo<number | null>(() => {
    if (typeof weight === 'number') {
      return 2.20462 * weight;
    }
    return null;
  }, [weight]);

  const commission = useMemo<number | null>(() => {
    let result: number | null = null;

    if (formula) {
      try {
        if (typeof price !== 'number') {
          throw new Error('price not number');
        }

        const r = evalWithParams(formula, {
          item_price: price,
          item_weight_kg: weight,
          item_weight_lbs: weightLbs,
        });

        if (typeof r !== 'number') {
          throw new Error('result not number');
        }
        result = r;
        form.setFieldValue('commission', result.toFixed(2));
      } catch (e) {
        // console.log('e', (e as Error).message);
      }
    }

    return result;
  }, [formula, price, weight, weightLbs, form]);

  useEffect(() => {
    if (formula) {
      try {
        evalWithParams(formula, {
          item_price: price,
          item_weight_kg: weight,
          item_weight_lbs: weightLbs,
        });
        setError(undefined);
      } catch (e) {
        setError((e as Error).message);
      }
    }
  }, [formula, price, weight, weightLbs]);

  return (
    <Form
      name={name}
      form={form}
      labelCol={{span: 8}}
      wrapperCol={{span: 16}}
      initialValues={{
        [`${name}-item_price`]: 1.52,
        [`${name}-item_weight_kg`]: 1.52,
      }}
      autoComplete="off"
      disabled={disabled}
    >
      <Form.Item
        label="Price"
        name={`${name}-item_price`}
        rules={[{required: true}]}
      >
        <InputNumber
          width="100%"
          addonAfter="$"
          value={price}
          disabled={isSaving || disabled}
          onChange={(value: number | null) => setPrice(value)}
        />
      </Form.Item>
      <Form.Item
        label="Weight"
        name={`${name}-item_weight_kg`}
        help={
          typeof weightLbs === 'number' &&
          `Automatically calculated variable item_weight_lbs = ${weightLbs.toFixed(
            2
          )}`
        }
        rules={[{required: true}]}
      >
        <InputNumber
          addonAfter="kg"
          value={weight}
          min={0}
          disabled={isSaving || disabled}
          onChange={(value: number | null) => setWeight(value)}
        />
      </Form.Item>

      <Divider style={{marginTop: 48}} />

      <Form.Item label="Commission" name="commission">
        {typeof commission === 'number' && (
          <Typography.Text>$ {commission.toFixed(2)}</Typography.Text>
        )}
        {typeof error === 'string' && (
          <Alert
            message={error}
            type="error"
            showIcon
            style={{marginTop: '-4px'}}
            icon={<Emoji emoji="❌" style={{marginRight: 8}} />}
          />
        )}
      </Form.Item>
    </Form>
  );
};
