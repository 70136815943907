import {AxiosInstance} from 'axios';
import {vars} from '../../vars';
import {TOKEN_KEY} from '../../authProvider';

export class GeographyService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  public async getAdmin1(country: string) {
    const url = `${vars.baseApiUrl}/logistics/geography/admin1?country=${country}`;
    const {data} = await this.httpClient.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });
    return data;
  }

  public async getAdmin2(admin1: string) {
    const url = `${vars.baseApiUrl}/logistics/geography/admin2?admin1_id=${admin1}`;
    const {data} = await this.httpClient.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });
    return data;
  }

  public async getAdmin3(admin2: string) {
    const url = `${vars.baseApiUrl}/logistics/geography/admin3?admin2_id=${admin2}`;
    const {data} = await this.httpClient.get(`${url}`, {
      headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });
    return data;
  }

  public async listShippingAddressByUserId(userId: string) {
    const query = new URLSearchParams();
    query.set('page', '0');
    query.set('size', '15');

    const url = `${vars.baseApiUrl}/logistics/admin/geography/shipping-address/user/${userId}`;
    const {data} = await this.httpClient.get(`${url}?${query.toString()}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });
    return data.content;
  }
}
