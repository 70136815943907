import React from 'react';

import logo from '../../../assets/img/logo.svg';

type TitleProps = {
  collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({collapsed}) => {
  return (
    <div
      style={{
        display: 'block',
        textAlign: 'center',
        overflow: 'hidden',
        margin: '16px auto',
        justifyContent: 'space-between',
        fontSize: 14,
        fontWeight: 'bold',
        borderBottom: 1,
        width: collapsed ? 23 : '100%',
        transition: 'width 0.35s',
      }}
    >
      <img src={logo} alt="Qempo" />
    </div>
  );
};
