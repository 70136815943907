import {
  CrudFilter,
  CrudFilters,
  getDefaultFilter,
  LogicalFilter,
} from '@refinedev/core';
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  Radio,
  Row,
  Select,
  Tag,
} from 'antd';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {SearchOutlined} from '@ant-design/icons';
import dayjs, {Dayjs} from 'dayjs';
import FormItemLabel from 'antd/es/form/FormItemLabel';

import './style.css';

export interface IPayoutFilterVariables {
  payoutId?: string;
  userId?: string;
  orderIds?: string[];
  states?: string[];
  payoutType: string;
  createdAt?: [Dayjs, Dayjs];
}
export const PayoutFilter: React.FC<{
  formProps: FormProps;
  setFilters: (filters: CrudFilters) => void;
  filters: CrudFilters;
  loading?: boolean;
}> = ({formProps, setFilters, filters, loading}) => {
  const {RangePicker} = DatePicker;
  const [orderId, setOrderId] = useState<string>('');
  const [payoutType, setPayoutType] = useState<'COURIER' | 'TRAVELER'>(() => {
    const payoutType = filters.find(
      (f) => (f as LogicalFilter).field === 'payout_type'
    );

    if (payoutType) {
      return payoutType.value;
    }
    return 'COURIER';
  });

  const orderIdsFilter = useMemo<CrudFilter | undefined>(() => {
    const oids = filters.find(
      (f) => (f as LogicalFilter).field === 'order_ids'
    );

    if (oids) {
      return oids;
    }
    return undefined;
  }, [filters]);

  const removeOrderId = useCallback(
    (orderId: string) => {
      const s = new Set<string>([orderId]);

      if (orderIdsFilter) {
        (orderIdsFilter.value as string[]).forEach((oid) => s.add(oid));
      }

      s.delete(orderId);

      setFilters([
        {
          field: 'order_ids',
          operator: 'in',
          value: Array.from(s),
        },
      ]);
    },
    [filters]
  );

  const addOrderId = useCallback(
    (orderId: string) => {
      const s = new Set<string>([orderId]);

      if (orderIdsFilter) {
        (orderIdsFilter.value as string[]).forEach((oid) => s.add(oid));
      }

      setFilters([
        {
          field: 'order_ids',
          operator: 'in',
          value: Array.from(s),
        },
      ]);
    },
    [setFilters, orderIdsFilter, filters]
  );

  useEffect(() => {
    if (!filters.some((f) => (f as LogicalFilter).field === 'payout_type')) {
      const f = [
        ...JSON.parse(JSON.stringify(filters)),
        {
          field: 'payout_type',
          operator: 'eq',
          value: 'COURIER',
        },
      ];

      setFilters(f as any);
    }
  }, [filters, setFilters]);

  const createdAt = useMemo(() => {
    const start = getDefaultFilter('createdAt', filters, 'gte');
    const end = getDefaultFilter('createdAt', filters, 'lte');

    const startFrom = dayjs(start);
    const endAt = dayjs(end);

    if (start && end) {
      return [startFrom, endAt];
    }
    return undefined;
  }, [filters]);

  return (
    <Form
      layout="vertical"
      {...formProps}
      initialValues={{
        payoutId: getDefaultFilter('payout_id', filters),
        payoutType: getDefaultFilter('payout_type', filters, 'eq') || 'COURIER',
        userId: getDefaultFilter('user_id', filters, 'eq'),
        orderIds: getDefaultFilter('order_ids', filters),
        states: getDefaultFilter('states', filters, 'in'),
        createdAt,
      }}
      onValuesChange={(changedValues: any) => {
        if (changedValues && changedValues.payoutType) {
          setPayoutType(changedValues.payoutType);
        }
      }}
    >
      <Row gutter={[10, 0]} align="bottom">
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label="Payout Id" name="payoutId">
            <Input placeholder="PO-XXXXXXXX" prefix={<SearchOutlined />} />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label="Payout type" name="payoutType">
            <Radio.Group>
              <Radio value="COURIER">COURIER</Radio>
              <Radio value="TRAVELER">TRAVELER</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item
            label={payoutType === 'COURIER' ? 'Courier ID' : 'Traveler ID'}
            name="userId"
          >
            <Input
              placeholder={
                payoutType === 'COURIER' ? 'CR-XXXXXXXX' : 'T-XXXXXXXX'
              }
              prefix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <div className="order-ids-wrap">
            <FormItemLabel prefixCls="ant-form" label="Order IDs" />

            {orderIdsFilter &&
              orderIdsFilter.value instanceof Array &&
              orderIdsFilter.value.length > 0 && (
                <div className="order-ids-tags-wrap">
                  {(orderIdsFilter.value as string[]).map((oid) => (
                    <Tag
                      key={oid}
                      closable={!loading}
                      onClose={() => removeOrderId(oid)}
                    >
                      {oid}
                    </Tag>
                  ))}
                </div>
              )}

            <Input
              placeholder="OR-XXXXXXXX"
              prefix={<SearchOutlined />}
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
              onPressEnter={(e) => {
                addOrderId(orderId);
                setOrderId('');
                e.preventDefault();
              }}
              allowClear
            />
          </div>
          {/*<Form.Item label="Order Ids" name="orderIds">
            <Input placeholder="OR-XXXXXXXX" prefix={<SearchOutlined />} />
          </Form.Item>*/}
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label="States" name="states">
            <Select
              allowClear
              mode="multiple"
              placeholder="States"
              options={[
                {label: 'PAYOUT_PENDING', value: 'PAYOUT_PENDING'},
                {label: 'PAYOUT_DONE', value: 'PAYOUT_DONE'},
              ]}
            />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label="Created At" name="createdAt">
            <RangePicker style={{width: '100%'}} />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item>
            <Button htmlType="submit" type="primary" size="large" block>
              Filter
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
