import React, {useState} from 'react';
import {Tabs} from 'antd';
import {useList} from '@refinedev/core';
import {List} from '@refinedev/antd';
import {CourierCategorySettingConfigs} from '../courier-category-setting-configs';
import {
  ICategory,
  ICourier,
  ICourierCategorySetting,
  IOrderServiceType,
} from '../../../../interfaces';
import {useMediaQuery} from '../../../../utils/react';

export const CourierCategorySetting: React.FC<{
  hideLite?: boolean;
  hideFull?: boolean;
  courier?: ICourier;
  liteCourierCategorySetting?: ICourierCategorySetting;
  fullCourierCategorySetting?: ICourierCategorySetting;
  onAfterSave: () => void;
}> = ({
  hideLite,
  hideFull,
  courier,
  liteCourierCategorySetting,
  fullCourierCategorySetting,
  onAfterSave,
}) => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [tab, setTab] = useState<IOrderServiceType>(
    !hideLite ? 'LITE' : 'FULL'
  );
  const {data} = useList<ICategory>({
    resource: 'categories',
  });

  const {data: categories} = data || {};

  const tabItems = [];

  if (!hideLite) {
    tabItems.push({
      label: 'Lite',
      key: 'LITE',
      children: categories && courier && courier.courierSetting && (
        <CourierCategorySettingConfigs
          categories={categories}
          courier={courier}
          orderServiceType="LITE"
          categorySetting={liteCourierCategorySetting}
          onAfterSave={onAfterSave}
        />
      ),
    });
  }

  if (!hideFull) {
    tabItems.push({
      label: 'Full',
      key: 'FULL',
      children: categories && courier && courier.courierSetting && (
        <CourierCategorySettingConfigs
          categories={categories}
          courier={courier}
          orderServiceType="FULL"
          categorySetting={fullCourierCategorySetting}
          onAfterSave={onAfterSave}
        />
      ),
    });
  }

  return (
    <List title={`Pricing ${tab}`} breadcrumb={null} canCreate={false}>
      <Tabs
        tabPosition={isMobile ? 'top' : 'left'}
        items={tabItems}
        defaultActiveKey={tab}
        onChange={(t) => setTab(t as IOrderServiceType)}
      />
    </List>
  );
};
