import React from 'react';
import {IResourceComponentsProps} from '@refinedev/core';
import {EditButton, List, ShowButton, useTable} from '@refinedev/antd';
import {ICourier} from '../../../interfaces';
import {Space, Table, Typography} from 'antd';
import {Emoji} from '../../../layout/components';

export const CourierList: React.FC<IResourceComponentsProps> = () => {
  const {tableProps} = useTable<ICourier>({
    resource: 'couriers',
    pagination: {
      mode: 'off',
    },
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id" pagination={false}>
        <Table.Column<ICourier>
          key="id"
          title="ID"
          render={(record) => (
            <Space>
              <Typography.Text style={{wordBreak: 'inherit'}}>
                {record.id}
              </Typography.Text>
            </Space>
          )}
        />
        <Table.Column<ICourier>
          key="email"
          title="Email"
          render={(record) => (
            <Space>
              <Typography.Text style={{wordBreak: 'inherit'}}>
                {record.email}
              </Typography.Text>
            </Space>
          )}
        />
        <Table.Column<ICourier>
          key="name"
          title="Name"
          render={(record) => (
            <Space>
              <Typography.Text style={{wordBreak: 'inherit'}}>
                {record.courierSetting.courierAddress.name}
              </Typography.Text>
            </Space>
          )}
        />
        <Table.Column<ICourier>
          key="verified"
          title="Verified"
          align="center"
          render={(_, record) => (
            <Space>
              {record.verified ? (
                <Emoji size={18} emoji="✅" />
              ) : (
                <Emoji size={18} emoji="❌" />
              )}
            </Space>
          )}
        />
        <Table.Column<ICourier>
          title="Actions"
          align="center"
          fixed="right"
          render={(_, record) => (
            <Space>
              <ShowButton
                icon={<Emoji emoji="👀" />}
                hideText
                size="middle"
                resource="couriers"
                recordItemId={record.id}
              />
              <EditButton
                icon={<Emoji emoji="✏️" />}
                hideText
                size="middle"
                resource="couriers"
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
