import {DataProvider} from '@refinedev/core';
import {AxiosInstance} from 'axios';
import {axiosInstance, generateFilter} from '@refinedev/simple-rest';
import {OrderService} from './service';
import {InvoiceService} from '../invoice/service';
import {CourierService} from '../courier/service';
import {unflatten} from 'flat';

export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance as AxiosInstance
): Omit<
  Required<DataProvider>,
  | 'deleteOne'
  | 'getMany'
  | 'update'
  | 'createMany'
  | 'updateMany'
  | 'deleteMany'
  | 'custom'
> => {
  const orderService = new OrderService(httpClient);
  const invoiceService = new InvoiceService(httpClient);
  const courierService = new CourierService(httpClient);

  return {
    create: async ({variables}) => {
      const {purchaserId, serviceType, courierId, items, shippingAddress, shippingAddressId, invoiceData, beforeDate} = unflatten<
        any,
        Record<string, any>
      >(variables);

      const orderItems = items.flatMap((item: any) => {
        const amount = parseInt(item.quantity);
        return Array(amount).fill({...item, quantity:1});
      });

      shippingAddress['userId'] = purchaserId;
      const order = await orderService.saveOrder({
        purchaserId,
        courierId, 
        serviceType,
        orderItems, 
        shippingAddressId,
        shippingAddress,
        invoiceData,
        beforeDate: beforeDate.$d.toISOString(),
      });

      return {
        data: order as any,
      };
    },
    //update: async ({id, variables}) => {
    //  const {firstName, lastName, email, altUserId, password} = unflatten<
    //    any,
    //    Record<string, string>
    //  >(variables);

    //  const order = await orderService.saveOrder({
    //    id: id as string,
    //    firstName,
    //    lastName,
    //    email,
    //    altUserId,
    //    password,
    //  });

    //  return {
    //    data: order as any,
    //  };
    //},
    getList: async ({pagination, filters}) => {
      const queryFilters = generateFilter(filters);

      const ordersPage = await orderService.getOrders(
        pagination?.current as number,
        pagination?.pageSize as number,
        queryFilters.order_id,
        queryFilters.state as any,
        [queryFilters.paidAt_gte, queryFilters.paidAt_lte]
      );

      return {
        data: ordersPage.content,
        total: ordersPage.totalElements,
      };
    },
    getOne: async ({id}) => {
      const order = await orderService.getOrder(id as string);

      order.invoice = await invoiceService.getInvoice(order.invoiceId);

      if (order.courierId) {
        order.courier = await courierService.getCourier(order.courierId);
      }

      return {
        data: order,
      };
    },
    getApiUrl: () => apiUrl,
  };
};
