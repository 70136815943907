import React from 'react';
import {Tag} from 'antd';

export const InvoiceState: React.FC<{state?: string}> = ({state}) => {
  let color = 'default';

  if (state === 'PENDING') color = 'cyan';
  if (state === 'RECEIVED') color = 'green';
  if (state === 'REFUND_PENDING') color = 'default';
  if (state === 'REFUNDED') color = 'blue';
  if (state === 'ABANDONED') color = 'red';

  return <Tag color={color}>{state}</Tag>;
};
