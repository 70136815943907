import React, {useEffect, useState} from 'react';
import {IOrderItemTracking} from '../../../../interfaces';
import {Edit, useForm} from '@refinedev/antd';
import {Card, Col, Form, Image, Input, Row, Upload} from 'antd';
import {InboxOutlined} from '@ant-design/icons';
import {UploadFile} from 'antd/es/upload/interface';

type Props = {
  orderItemId: string;
  orderId: string;
};

export const OrderItemTrackingEdit: React.FC<Props> = ({
  orderItemId,
  orderId,
}) => {
  const {
    formProps,
    saveButtonProps,
    queryResult: orderItemTrackingQueryResult,
  } = useForm<IOrderItemTracking>({
    resource: 'orderItemTrackings',
    redirect: false,
    id: orderItemId,
    action: 'edit',
    meta: {
      orderItemId,
      orderId,
    },
  });
  const {isLoading, isFetching, isRefetching, data} =
    orderItemTrackingQueryResult || {};

  const orderItemTracking = data?.data;

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (orderItemTracking?.invoiceFile) {
      setFileList([
        {
          uid: orderItemTracking.invoiceFile.id,
          name: orderItemTracking.invoiceFile.fileName,
          status: 'done',
          url: orderItemTracking.invoiceFile.publicUrl,
        },
      ]);
    }
  }, [orderItemTracking]);

  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Edit
      isLoading={isLoading || isFetching || isRefetching}
      saveButtonProps={saveButtonProps}
      breadcrumb={null}
      goBack={null}
      headerButtons={() => null}
      title="Edit order item tracking"
    >
      <Form
        {...formProps}
        style={{marginTop: 30}}
        layout="vertical"
        initialValues={{
          ...formProps.initialValues,
          orderNumber: formProps.initialValues?.orderNumber || '',
          trackingNumber: formProps.initialValues?.trackingNumber || '',
        }}
        disabled={isLoading || isFetching || isRefetching}
      >
        <Row key={`${orderItemId}-tracking-info`} gutter={20}>
          <Col sm={24} md={12}>
            <Form.Item label="Tracking number" name="trackingNumber">
              <Input />
            </Form.Item>
            <Form.Item label="Order number" name="orderNumber">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row key={`${orderItemId}-tracking-invoice`} gutter={20}>
          <Col sm={24} md={12}>
            <Card>
              <Form.Item
                name="invoiceFile"
                label="Invoice file"
                getValueFromEvent={getFile}
              >
                <Upload.Dragger
                  name="invoiceFile"
                  listType="picture"
                  fileList={fileList}
                  customRequest={(options) => {
                    if (options.onSuccess) {
                      options.onSuccess(options.file);
                    }
                  }}
                  accept="image/png, image/jpeg, image/jpg"
                  maxCount={1}
                  multiple={false}
                  onChange={({fileList: newFileList}) =>
                    setFileList(newFileList)
                  }
                  onRemove={() => false}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Only single png, jpg, jpeg files
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Card>
          </Col>
          <Col sm={24} md={12}>
            <Card>
              <Form.Item
                label="Invoice file url"
                name="invoiceFileUrl"
                rules={[{type: 'url'}]}
                style={{marginBottom: '4px'}}
              >
                <Input />
              </Form.Item>
              {orderItemTracking?.invoiceFileUrl && (
                <Image
                  width={200}
                  height={200}
                  src={orderItemTracking?.invoiceFileUrl}
                  draggable={false}
                  style={{objectFit: 'scale-down'}}
                  wrapperStyle={{display: 'block', margin: '0 auto'}}
                />
              )}
            </Card>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
  // return <pre>{JSON.stringify(orderItemTracking, null, 2)}</pre>;
};
