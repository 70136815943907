import {AxiosInstance} from 'axios';
import {vars} from '../../vars';
import {TOKEN_KEY} from '../../authProvider';
import {SaveShippingAddressRequest} from './dataProvider';
export class OrderShippingAddressService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  public async getShippingAddress(orderId: string) {
    const url = `${vars.baseApiUrl}/logistics/admin/order/${orderId}/shipping-address`;
    const {data} = await this.httpClient.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });
    return data;
  }

  public async updateShippingAddress(request: SaveShippingAddressRequest) {
    const {orderId, ...body} = request;
    const url = `${vars.baseApiUrl}/logistics/admin/order/${orderId}/shipping-address`;

    const {data} = await this.httpClient.post(`${url}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });

    return data;
  }
}
