import {TOKEN_KEY} from '../../authProvider';
import {AxiosInstance} from 'axios';
import {vars} from '../../vars';
import {ICourier, IOrderServiceType} from '../../interfaces';

export type SaveCourierRequest = {
  id?: string;
  email?: string;
  password?: string;
  verified?: boolean;
  hasCustoms?: boolean;
  courierAddress: {
    id: string;
    name: string;
    phone: string;
    phoneCountry: string;
    country: string;
    admin1Id: number;
    admin2Id: number;
    admin3Id?: number;
    address1: string;
    address2?: string;
    zipCode: string;
  };
};

export type SaveCourierPricingRequest = {
  courierId: string;
  orderServiceType: IOrderServiceType;
  pricingCategories: {
    categoryId: string;
    subCategoryId?: string;
    formula: string;
    enabled: boolean;
  }[];
};

export type DeletePricingCommissionFormulaRequest = {
  courierId: string;
  orderServiceType: IOrderServiceType;
  pricingCommissionFormulaId: string;
};

export type SaveCourierCategorySettingRequest = {
  courierId: string;
  orderServiceType: IOrderServiceType;
  settingConfigs: {
    categoryId: string;
    subCategoryId?: string;
    deliveryDays: number;
  }[];
};

export type EnableCourierPricingCommissionRequest = {
  courierId: string;
  orderServiceType: IOrderServiceType;
  enabled: boolean;
};

export class CourierService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }
  public async getCouriers() {
    const url = `${vars.baseApiUrl}/usher/admin/courier`;
    const {data} = await this.httpClient.get(`${url}?`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });
    return await Promise.all(
      data.map(async (c: ICourier) => {
        const courier = {...c};
        const urlSetting = `${vars.baseApiUrl}/logistics/admin/courier/set-up/${courier.id}`;

        const {data: settingData} = await this.httpClient.get(
          `${urlSetting}?`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
            },
          }
        );

        courier.courierSetting = settingData;

        return courier;
      })
    );
  }

  public async getCourier(courierId: string) {
    const url = `${vars.baseApiUrl}/usher/admin/courier?courier_id=${courierId}`;
    const urlSetting = `${vars.baseApiUrl}/logistics/admin/courier/set-up/${courierId}`;

    const {data: courierList} = await this.httpClient.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });

    const courier = courierList[0];

    try {
      const {data: courierSetting} = await this.httpClient.get(urlSetting, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      });
      courier.courierSetting = courierSetting;
    } catch (e) {
      console.error('Could not get courierSetting', e);
    }

    return courier;
  }

  public async saveCourier(request: SaveCourierRequest) {
    let courier: ICourier;

    if (!request.id) {
      const url = `${vars.baseApiUrl}/usher/admin/courier`;

      const {data: createdCourier} = await this.httpClient.post(
        url,
        {
          email: request.email,
          password: request.password,
          verified: request.verified,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
          },
        }
      );

      courier = createdCourier;
    } else {
      const url = `${vars.baseApiUrl}/usher/admin/courier/${request.id}`;

      const {data: updatedCourier} = await this.httpClient.put(
        url,
        {
          email: request.email,
          password: request.password,
          verified: request.verified,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
          },
        }
      );

      courier = updatedCourier;
    }

    const setUpUrl = `${vars.baseApiUrl}/logistics/admin/courier/set-up/${courier.id}`;

    await this.httpClient.post(
      setUpUrl,
      {
        hasCustoms: !!request.hasCustoms,
        courierAddress: {
          name: request.courierAddress.name,
          phone: request.courierAddress.phone,
          phoneCountry: request.courierAddress.phoneCountry,
          country: request.courierAddress.country,
          admin1Id: request.courierAddress.admin1Id,
          admin2Id: request.courierAddress.admin2Id,
          admin3Id: request.courierAddress.admin3Id,
          address1: request.courierAddress.address1,
          address2: request.courierAddress.address2,
          zipCode: request.courierAddress.zipCode,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      }
    );

    return await this.getCourier(courier.id);
  }

  public async deleteCourierPricingCommissionFormula(
    request: DeletePricingCommissionFormulaRequest
  ) {
    const url = `${vars.baseApiUrl}/logistics/admin/courier/set-up/${
      request.courierId
    }/${request.orderServiceType.toLowerCase()}/pricing/formula/${
      request.pricingCommissionFormulaId
    }/delete`;

    await this.httpClient.post(url, undefined, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });
  }

  public async saveCourierPricing(request: SaveCourierPricingRequest) {
    const url = `${vars.baseApiUrl}/logistics/admin/courier/set-up/${
      request.courierId
    }/${request.orderServiceType.toLowerCase()}/pricing`;

    await this.httpClient.post(
      url,
      {
        pricingCategories: request.pricingCategories,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      }
    );
  }

  public async saveCourierCategorySetting(
    request: SaveCourierCategorySettingRequest
  ) {
    const url = `${vars.baseApiUrl}/logistics/admin/courier/set-up/${
      request.courierId
    }/${request.orderServiceType.toLowerCase()}/category-setting`;

    await this.httpClient.post(
      url,
      {
        settingConfigs: request.settingConfigs,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      }
    );
  }

  public async enableCourierPricingCommission(
    request: EnableCourierPricingCommissionRequest
  ) {
    const url = `${vars.baseApiUrl}/logistics/admin/courier/enable/${
      request.courierId
    }/${request.orderServiceType.toLowerCase()}/pricing`;

    await this.httpClient.post(
      url,
      {
        enabled: request.enabled,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      }
    );
  }
}
