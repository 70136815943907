import {AxiosInstance} from 'axios';
import {vars} from '../../vars';
import {TOKEN_KEY} from '../../authProvider';
import {UploadFile} from 'antd/es/upload/interface';

export type RegisterItemsRequest = {
  orderItemId: string;
  orderNumber: string;
  trackingNumber: string;
  invoiceFile?: UploadFile[];
  invoiceFileUrl?: string;
};

export class OrderItemTrackingService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  public async getOrderItem(orderItemId: string) {
    const url = `${vars.baseApiUrl}/logistics/admin/order/item/${orderItemId}`;
    const {data} = await this.httpClient.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });
    return data;
  }
  public async registerOrder(
    orderId: string,
    registerItemsRequest: RegisterItemsRequest[]
  ) {
    const url = `${vars.baseApiUrl}/logistics/admin/order/${orderId}/register`;

    const fd = new FormData();

    registerItemsRequest.forEach((ir, idx) => {
      fd.append(`orderItemTracking[${idx}].orderItemId`, ir.orderItemId);
      if (ir.orderNumber) {
        fd.append(`orderItemTracking[${idx}].orderNumber`, ir.orderNumber);
      }
      if (ir.trackingNumber) {
        fd.append(
          `orderItemTracking[${idx}].trackingNumber`,
          ir.trackingNumber
        );
      }
      if (ir.invoiceFileUrl) {
        fd.append(
          `orderItemTracking[${idx}].invoiceFileUrl`,
          ir.invoiceFileUrl
        );
      }

      if (
        ir.invoiceFile &&
        Array.isArray(ir.invoiceFile) &&
        ir.invoiceFile.length > 0 &&
        ir.invoiceFile[0].response
      ) {
        fd.append(
          `orderItemTracking[${idx}].invoiceFile`,
          ir.invoiceFile[0].response
        );
      }
    });

    const {data} = await this.httpClient.post(`${url}`, fd, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });

    return data;
  }
}
