import {DataProvider} from '@refinedev/core';
import {AxiosInstance} from 'axios';
import {unflatten} from 'flat';
import {axiosInstance} from '@refinedev/simple-rest';
import {CourierService} from './service';

export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance as AxiosInstance
): Omit<
  Required<DataProvider>,
  | 'deleteOne'
  | 'getMany'
  | 'createMany'
  | 'updateMany'
  | 'deleteMany'
  | 'custom'
> => {
  const courierService = new CourierService(httpClient);

  return {
    getList: async () => {
      const couriers = await courierService.getCouriers();

      return {
        data: couriers,
        total: couriers.length,
      };
    },
    create: async ({variables}) => {
      const {email, password, verified, courierAddress} = unflatten<
        any,
        Record<string, any>
      >(variables);

      const courier = await courierService.saveCourier({
        email,
        password,
        verified,
        courierAddress,
      });

      return {
        data: courier,
      };
    },
    update: async ({id, variables}) => {
      const vars = unflatten<any, Record<string, any>>(variables);

      const {email, password, verified, courierAddress} = vars;

      const courier = await courierService.saveCourier({
        id: id as string,
        email,
        password,
        verified,
        courierAddress,
      });

      return {
        data: courier,
      };
    },
    getOne: async ({id}) => {
      const courier = await courierService.getCourier(id as string);

      return {
        data: courier,
      };
    },
    getApiUrl: () => apiUrl,
  };
};
