import {DataProvider} from '@refinedev/core';
import {AxiosInstance} from 'axios';
import {axiosInstance, generateFilter} from '@refinedev/simple-rest';
import {PayoutService} from './service';

export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance as AxiosInstance
): Omit<
  Required<DataProvider>,
  | 'create'
  | 'getOne'
  | 'update'
  | 'deleteOne'
  | 'getMany'
  | 'createMany'
  | 'updateMany'
  | 'deleteMany'
  | 'custom'
> => {
  const payoutService = new PayoutService(httpClient);

  return {
    getList: async ({pagination, filters}) => {
      const queryFilters = generateFilter(filters);

      const payoutsPage = await payoutService.getPayouts(
        pagination?.current as number,
        pagination?.pageSize as number,
        queryFilters.payout_id as string,
        queryFilters.order_ids as any,
        queryFilters.states as any,
        [queryFilters.createdAt_gte, queryFilters.createdAt_lte],
        queryFilters.payout_type as any
      );

      return {
        data: payoutsPage.content,
        total: payoutsPage.totalElements,
      };
    },
    getApiUrl: () => apiUrl,
  };
};
