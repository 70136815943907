import React, {useCallback, useContext, useMemo, useState} from 'react';
import classNames from 'classnames';
import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Collapse,
  message,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import {
  ICategory,
  ICourier,
  IOrderServiceType,
  IPricingCommission,
  IPricingCommissionFormula,
  ISubCategory,
} from '../../../../interfaces';
import {Emoji} from '../../../../layout/components';
import {ColorModeContext} from '../../../../layout/contexts/color-mode';
import {FormulaEditor, FormulaTester} from '../formula-editor';
import {evalWithParams} from '../../../../utils/react';
import {
  CourierService,
  DeletePricingCommissionFormulaRequest,
  EnableCourierPricingCommissionRequest,
  SaveCourierPricingRequest,
} from '../../../courier/service';
import {axiosInstance} from '@refinedev/simple-rest';
import {AxiosInstance, AxiosError} from 'axios';

import './index.scss';

type HeaderColorState = 'success' | 'warning';

const courierService = new CourierService(axiosInstance as AxiosInstance);

export const PricingFormula: React.FC<{
  initialFormula?: IPricingCommissionFormula;
  orderServiceType: IOrderServiceType;
  courier: ICourier;
  category: ICategory;
  subCategory?: ISubCategory;
  onAfterSave: () => void;
  formulaDisabled?: boolean;
}> = ({
  initialFormula,
  orderServiceType,
  category,
  subCategory,
  courier,
  onAfterSave,
  formulaDisabled,
  ...props
}) => {
  const {mode} = useContext(ColorModeContext);
  const [formula, setFormula] = useState<string | undefined>(
    initialFormula?.formula
  );
  const [isSaving, setSaving] = useState(false);
  const [isValid, setValid] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const headerColor = useMemo<HeaderColorState | undefined>(() => {
    if (
      (!!initialFormula?.formula && !formula) ||
      (!!initialFormula?.formula &&
        !!formula &&
        initialFormula?.formula === formula)
    )
      return 'success';
    if (formula) return 'warning';
  }, [initialFormula, formula]);

  const validate = useCallback(
    (formula: string) => {
      try {
        const r = evalWithParams(formula, {
          item_price: 1.52,
          item_weight_kg: 1.52,
          item_weight_lbs: 1.52,
        });

        if (typeof r !== 'number') {
          throw new Error('result not number');
        }

        setValid(true);
      } catch (e) {
        setValid(false);
      }
    },
    [setValid]
  );

  const deleteFormula = useCallback(() => {
    if (initialFormula && !isSaving) {
      setSaving(true);

      const request: DeletePricingCommissionFormulaRequest = {
        courierId: courier.id,
        pricingCommissionFormulaId: initialFormula.id,
        orderServiceType,
      };

      courierService
        .deleteCourierPricingCommissionFormula(request)
        .then(() => {
          messageApi
            .open({
              type: 'success',
              content: `Deleted (${orderServiceType}) (${category.id}) pricing formula: ${initialFormula.id}`,
            })
            .then(() => {
              setFormula(undefined);
              setSaving(false);
              setValid(false);
              onAfterSave();
            });
        })
        .catch((e) => {
          messageApi
            .open({
              type: 'error',
              content: `Could not delete (${orderServiceType}) (${
                category.id
              }) pricing formula ${initialFormula.id}: ${
                (e as AxiosError<{error: string; message: string}>).response
                  ?.data?.error
              }`,
            })
            .then(() => {
              setSaving(false);
            });
        });
    }
  }, [
    isSaving,
    setSaving,
    setValid,
    courier,
    orderServiceType,
    category,
    onAfterSave,
    initialFormula,
  ]);

  const saveFormula = useCallback(
    (formula?: string) => {
      if (!isSaving && formula) {
        setSaving(true);

        const pricingCategory: {
          categoryId: string;
          formula: string;
          subCategoryId?: string;
          enabled: boolean;
        } = {
          categoryId: category.id,
          formula,
          // This is because you cannot edit a disabled formula
          enabled: true,
        };

        if (subCategory) {
          pricingCategory.subCategoryId = subCategory.id;
        }

        const request: SaveCourierPricingRequest = {
          courierId: courier.id,
          orderServiceType,
          pricingCategories: [pricingCategory],
        };

        courierService
          .saveCourierPricing(request)
          .then(() => {
            messageApi
              .open({
                type: 'success',
                content: `Successfully saved (${orderServiceType}) (${category.id}) pricing`,
              })
              .then(() => {
                setSaving(false);
                setValid(false);
                onAfterSave();
              });
          })
          .catch((e) => {
            messageApi
              .open({
                type: 'error',
                content: `Could not set (${orderServiceType}) (${
                  category.id
                }) pricing: ${
                  (e as AxiosError<{error: string; message: string}>).response
                    ?.data?.error
                }`,
              })
              .then(() => {
                setSaving(false);
              });
          });
      }
    },
    [
      isSaving,
      setSaving,
      setValid,
      courier,
      orderServiceType,
      category,
      subCategory,
      onAfterSave,
    ]
  );

  const extra = useMemo(() => {
    let statusEmoji;
    let subCategoriesEmoji;

    if (subCategory) {
      subCategoriesEmoji = (
        <Emoji emoji={'🔗'} size={18} style={{top: 10, display: 'inherit'}} />
      );
    }

    if (headerColor) {
      if (headerColor === 'success') {
        statusEmoji = (
          <Emoji emoji={'✅'} size={18} style={{top: 10, display: 'inherit'}} />
        );
      }
      if (headerColor === 'warning') {
        statusEmoji = (
          <Emoji emoji={'✏️'} size={18} style={{top: 10, display: 'inherit'}} />
        );
      }
    }

    return (
      <div style={{position: 'absolute', right: 16}}>
        <Space direction="horizontal">
          {subCategoriesEmoji}
          {statusEmoji}
        </Space>
      </div>
    );
  }, [headerColor, category, subCategory]);

  const button = useMemo(() => {
    if (!isValid && formula) {
      return (
        <Button
          type="primary"
          size="large"
          disabled={isSaving || headerColor !== 'warning' || formulaDisabled}
          loading={isSaving}
          onClick={() => validate(formula)}
          style={{display: 'block', marginLeft: 'auto'}}
        >
          Validate
        </Button>
      );
    }

    return (
      <Button
        type="primary"
        size="large"
        disabled={isSaving || headerColor !== 'warning' || formulaDisabled}
        loading={isSaving}
        onClick={() => saveFormula(formula)}
        style={{display: 'block', marginLeft: 'auto'}}
      >
        Save <Emoji emoji="💾" style={{marginLeft: 4}} />
      </Button>
    );
  }, [isSaving, isValid, validate, formula, headerColor, formulaDisabled]);

  const deleteButton = (
    <Button
      type="primary"
      danger
      size="large"
      disabled={isSaving || formulaDisabled}
      loading={isSaving}
      onClick={() => deleteFormula()}
      style={{display: 'block', marginLeft: 'auto'}}
    >
      Delete <Emoji emoji="🗑️" style={{marginLeft: 4}} />
    </Button>
  );

  const breadcrumbItems = [
    {
      title: `${category.name} ${!subCategory ? '(' + category.id + ')' : ''}`,
    },
  ];

  if (subCategory)
    breadcrumbItems.push({
      title: `${subCategory.name} (${subCategory.id})`,
    });

  return (
    <>
      {contextHolder}
      <Collapse.Panel
        key={category.id}
        className={classNames(headerColor, {
          dark: mode === 'dark',
          light: mode === 'light',
          'pricing-subcategory': !!subCategory,
        })}
        showArrow
        header={
          <Space direction="horizontal">
            <Breadcrumb items={breadcrumbItems} style={{margin: 0}} />
            {!!subCategory && (
              <Tooltip
                placement="top"
                title={subCategory.description}
                trigger="hover"
              >
                {' '}
                <Emoji
                  emoji={'ℹ️'}
                  style={{top: 10, display: 'inherit', cursor: 'pointer'}}
                />{' '}
              </Tooltip>
            )}
          </Space>
        }
        extra={extra}
        {...props}
      >
        <div>
          <Space direction="vertical" style={{width: '100%'}}>
            <Row gutter={[16, 16]}>
              <Col lg={24} xl={16} style={{width: '100%'}}>
                <div style={{display: 'block'}}>
                  <FormulaEditor
                    value={formula}
                    setValue={(v) => {
                      setValid(false);
                      setFormula(v);
                    }}
                    isSaving={isSaving}
                    disabled={formulaDisabled}
                  />
                </div>
              </Col>
              <Col lg={24} xl={8}>
                <FormulaTester
                  name={`formula-test-${category.id}`}
                  formula={formula}
                  isSaving={isSaving}
                  disabled={formulaDisabled}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Space
                  direction="horizontal"
                  style={{width: '100%', justifyContent: 'space-between'}}
                >
                  <Space direction="horizontal" style={{width: '100%'}}>
                    {button}
                    {isValid && (
                      <Alert
                        message="Verified"
                        type="success"
                        showIcon
                        icon={<Emoji emoji="✅" style={{marginRight: 8}} />}
                      />
                    )}
                  </Space>
                  {headerColor === 'success' && deleteButton}
                </Space>
              </Col>
            </Row>
          </Space>
        </div>
      </Collapse.Panel>
    </>
  );
};

export const PricingFormulas: React.FC<{
  categories: ICategory[];
  courier: ICourier;
  orderServiceType: IOrderServiceType;
  pricingCommission?: IPricingCommission;
  onAfterSave: () => void;
  onAfterEnable: () => void;
}> = ({
  categories,
  courier,
  orderServiceType,
  pricingCommission,
  onAfterSave,
  onAfterEnable,
}) => {
  const [isEnabling, setIsEnabling] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const {mode} = useContext(ColorModeContext);

  const rows = useMemo(() => {
    return categories.flatMap((category) => {
      const foundFormula = pricingCommission?.pricingCommissionFormulas.find(
        (pcf) => pcf.category.id === category.id && !pcf.subCategory
      );

      const pricingFormulas = [
        <PricingFormula
          key={category.id}
          category={category}
          courier={courier}
          orderServiceType={orderServiceType}
          initialFormula={foundFormula}
          onAfterSave={onAfterSave}
          formulaDisabled={!foundFormula?.enabled}
        />,
      ];

      if (category.subCategories.length) {
        category.subCategories.forEach((sc) => {
          const foundFormula =
            pricingCommission?.pricingCommissionFormulas.find(
              (pcf) =>
                pcf.category.id === category.id &&
                pcf.subCategory &&
                pcf.subCategory.id === sc.id
            );

          pricingFormulas.push(
            <PricingFormula
              key={sc.id}
              category={category}
              subCategory={sc}
              courier={courier}
              orderServiceType={orderServiceType}
              initialFormula={foundFormula}
              onAfterSave={onAfterSave}
              formulaDisabled={!foundFormula?.enabled}
            />
          );
        });
      }

      return pricingFormulas;
    });
  }, [categories, pricingCommission, mode, onAfterSave]);

  const enableCourierPricing = useCallback(
    (enabled: boolean) => {
      if (!isEnabling) {
        setIsEnabling(true);

        const request: EnableCourierPricingCommissionRequest = {
          courierId: courier.id,
          orderServiceType,
          enabled,
        };

        courierService
          .enableCourierPricingCommission(request)
          .then(() => {
            onAfterEnable();
            messageApi
              .open({
                type: 'success',
                content: `Enabled (${orderServiceType}) pricing commission for courier ${request.courierId}`,
              })
              .then(() => {
                setIsEnabling(false);
              });
          })
          .catch((e) => {
            messageApi
              .open({
                type: 'error',
                content: `Could not enable (${orderServiceType}) pricing commission for courier ${
                  request.courierId
                }: ${
                  (e as AxiosError<{error: string; message: string}>).response
                    ?.data?.error
                }`,
              })
              .then(() => {
                setIsEnabling(false);
              });
          });
      }
    },
    [isEnabling, setIsEnabling, onAfterEnable]
  );

  return (
    <>
      {contextHolder}
      <Space direction="vertical" style={{width: '100%'}} size="large">
        {pricingCommission && pricingCommission.enabled && (
          <>
            <Space direction="vertical">
              <Typography.Text style={{wordBreak: 'inherit'}}>
                Available variables:{' '}
              </Typography.Text>
              <div>
                <Tag color="blue" style={{marginTop: 4, marginBottom: 4}}>
                  item_price
                </Tag>
                <Tag color="blue" style={{marginTop: 4, marginBottom: 4}}>
                  item_weight_kg
                </Tag>
                <Tag color="blue" style={{marginTop: 4, marginBottom: 4}}>
                  item_weight_lbs
                </Tag>
              </div>
            </Space>
            <Collapse accordion>{rows}</Collapse>
            <br />
            <Button
              type="primary"
              size="large"
              disabled={isEnabling}
              loading={isEnabling}
              onClick={() => enableCourierPricing(false)}
            >
              Disable <Emoji emoji="🔴" style={{marginLeft: 4}} />
            </Button>
          </>
        )}
        {pricingCommission && !pricingCommission.enabled && (
          <Space direction="vertical">
            <Alert
              message="This pricing commission is disabled"
              type="error"
              showIcon
              style={{marginTop: '-4px'}}
              icon={<Emoji emoji="❌️" style={{marginRight: 8}} />}
            />
            <Button
              type="primary"
              size="large"
              disabled={isEnabling}
              loading={isEnabling}
              onClick={() => enableCourierPricing(true)}
            >
              Enable <Emoji emoji="🟢" style={{marginLeft: 4}} />
            </Button>
          </Space>
        )}
      </Space>
    </>
  );
};
