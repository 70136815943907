import {DataProvider} from '@refinedev/core';
import {AxiosInstance} from 'axios';
import {axiosInstance} from '@refinedev/simple-rest';
import {OrderShippingAddressService} from './service';

export type SaveShippingAddressRequest = {
  orderId?: string;
  firstName: string;
  lastName: string;
  idNumber: string;
  phone: string;
  phoneCountry: string;
  country: string;
  admin1Id: number;
  admin2Id: number;
  admin3Id?: number;
  address1: string;
  address2?: string;
  zipCode: string;
  userId: string;
};

export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance as AxiosInstance
): Omit<
  Required<DataProvider>,
  | 'getList'
  | 'create'
  | 'deleteOne'
  | 'getMany'
  | 'createMany'
  | 'updateMany'
  | 'deleteMany'
  | 'custom'
> => {
  const shippingAddressService = new OrderShippingAddressService(httpClient);

  return {
    getOne: async ({id}) => {
      const shippingAddress = await shippingAddressService.getShippingAddress(
        id as string
      );

      return {
        data: shippingAddress,
      };
    },
    update: async ({id, variables}) => {
      const order = await shippingAddressService.updateShippingAddress({
        ...(variables as SaveShippingAddressRequest),
        orderId: id as string,
      });

      return {
        data: order.shippingAddress,
      };
    },
    getApiUrl: () => apiUrl,
  };
};
