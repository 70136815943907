import React, {useEffect, useMemo, useState} from 'react';
import {IOrder, IOrderLogisticType} from '../../../../interfaces';
import {Modal, ModalProps, Radio, Row, Col, Input, message} from 'antd';
import {Emoji} from 'layout/components';
import {axiosInstance} from '@refinedev/simple-rest';
import {AxiosError, AxiosInstance} from 'axios';
import {OrderService} from '../../service';

const orderService = new OrderService(axiosInstance as AxiosInstance);

export const OrderAssignLogisticModal: React.FC<
  {
    order: IOrder;
    preferredLogisticType?: IOrderLogisticType;
  } & ModalProps
> = ({
  order,
  preferredLogisticType,
  title,
  confirmLoading,
  okButtonProps,
  cancelButtonProps,
  onOk: _onOk,
  ...props
}) => {
  const [selected, setSelected] = useState<IOrderLogisticType | undefined>(
    () => {
      if (order.courierId) return 'COURIER';
      if (order.flight) return 'FLIGHT';
      return preferredLogisticType;
    }
  );

  const [selectedId, setSelectedId] = useState<string>('');

  const [isLoading, setLoading] = useState(false);

  const [validForm, setValidForm] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const courierContent = useMemo(() => {
    if (selected !== 'COURIER') return null;
    return (
      <>
        <Input
          value={selectedId}
          disabled={isLoading || confirmLoading}
          onChange={(e) => setSelectedId(e.target.value)}
          addonBefore={
            <>
              Courier Id: <Emoji emoji="📦" />
            </>
          }
        />
      </>
    );
  }, [order, selected, selectedId, setSelectedId, isLoading, confirmLoading]);

  const onOk = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (selected) {
      setLoading(true);
      try {
        await orderService.setLogisticType(
          order.id,
          selected as IOrderLogisticType,
          selectedId
        );
        messageApi.open({
          type: 'success',
          content: 'Set logistic type to: COURIER',
        });
      } catch (e) {
        setLoading(false);
        messageApi.open({
          type: 'error',
          content: `Could not assign logistic type: ${
            (e as AxiosError<{error: string; message: string}>).response?.data
              ?.error
          }`,
        });
        return;
      }

      setLoading(false);
      if (_onOk) _onOk(e);
    }
  };

  useEffect(() => {
    setSelectedId('');
  }, [selected, setSelectedId]);

  useEffect(() => {
    setValidForm(!!selectedId);
  }, [selectedId, validForm, setValidForm]);

  return (
    <>
      {contextHolder}
      <Modal
        title={
          title || (
            <>
              <Emoji emoji="📥" /> Assign Logistic Type for Order: {order.id}
            </>
          )
        }
        confirmLoading={isLoading || confirmLoading}
        okButtonProps={{
          ...okButtonProps,
          disabled:
            !validForm ||
            isLoading ||
            confirmLoading ||
            okButtonProps?.disabled,
        }}
        cancelButtonProps={{
          ...cancelButtonProps,
          disabled: isLoading || confirmLoading || okButtonProps?.disabled,
        }}
        onOk={onOk}
        {...props}
      >
        <Row gutter={[16, 16]} style={{marginTop: 16}}>
          <Col xs={24} style={{textAlign: 'center'}}>
            <Radio.Group
              value={selected}
              disabled={isLoading || confirmLoading}
              onChange={(e) => setSelected(e.target.value)}
            >
              <Radio.Button
                disabled={isLoading || confirmLoading}
                value="COURIER"
              >
                Courier
              </Radio.Button>
              <Radio.Button
                disabled={isLoading || confirmLoading}
                value="TRAVELER"
              >
                Traveler
              </Radio.Button>
            </Radio.Group>
          </Col>
          <Col xs={24}>{courierContent}</Col>
        </Row>
      </Modal>
    </>
  );
};
