import {useEffect, useState} from 'react';

export function useMediaQuery(query: string): boolean {
  const getMatches = (query: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState<boolean>(getMatches(query));

  function handleChange() {
    setMatches(getMatches(query));
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen matchMedia
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener('change', handleChange);
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener('change', handleChange);
      }
    };
  }, [query]);

  return matches;
}

export function evalWithParams(
  code: string,
  params: Record<string, number | string | null | undefined>
): any {
  let variables = '';

  for (const p in params) {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(p)) {
      if (!['undefined', 'null'].includes(typeof params[p])) {
        variables += `var ${p} = ${params[p]};\n`;
      }
    }
  }
  return eval(variables + code);
}
