import React, {useCallback, useState} from 'react';
import {
  IInvoice,
  IInvoiceSummary,
  IOrderLogisticType,
  IOrderServiceType,
} from '../../../../interfaces';
import {Button, Card, Descriptions, Modal, Space, Typography} from 'antd';
import {NumberField} from '@refinedev/antd';
import {InvoiceState} from '../../../../layout/components/invoice-state';
import {DollarCircleOutlined} from '@ant-design/icons';
import {axiosInstance} from '@refinedev/simple-rest';
import {AxiosInstance} from 'axios';
import {InvoiceService} from '../../../invoice/service';

const invoiceService = new InvoiceService(axiosInstance as AxiosInstance);

export const Invoice: React.FC<{
  invoice?: IInvoice;
  serviceType?: IOrderServiceType;
  logisticType?: IOrderLogisticType;
  refetch: () => Promise<any>;
}> = ({invoice, serviceType, logisticType, refetch}) => {
  const [refundModalOpen, setRefundModalOpen] = useState<boolean>(false);
  const [loadingRefund, setLoadingRefund] = useState<boolean>(false);

  const refundInvoice = useCallback(() => {
    if (invoice) {
      setLoadingRefund(true);
      invoiceService
        .refundInvoice(invoice.id)
        .then(() => {
          setLoadingRefund(false);
          refetch().then(() => {
            setRefundModalOpen(false);
          });
        })
        .catch(() => setLoadingRefund(false));
    }
  }, [invoice, refetch, setLoadingRefund, setRefundModalOpen]);

  const getInvoiceConceptLabel = useCallback(
    (concept: keyof IInvoiceSummary) => {
      switch (concept) {
        case 'delivery':
          return 'delivery (last_mile)';
        case 'commission':
          return `commission (${
            logisticType === 'COURIER' ? 'courier' : 'traveler'
          })`;
        case 'qempo':
          return 'qempo (commission)';
        default:
          return concept;
      }
    },
    [serviceType]
  );

  return (
    <>
      <Card
        title={
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <span>Invoice: {invoice?.id}</span>
            {invoice?.state === 'REFUND_PENDING' && (
              <Button
                type="primary"
                size="small"
                onClick={() => setRefundModalOpen(true)}
              >
                <DollarCircleOutlined />
                Refund
              </Button>
            )}
          </div>
        }
      >
        <Space
          direction="vertical"
          size="small"
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <Typography.Text>
            <strong>State:</strong> <InvoiceState state={invoice?.state} />
          </Typography.Text>
          <Descriptions
            size="small"
            title={
              <Typography.Text>
                <strong>Summary:</strong>
              </Typography.Text>
            }
            bordered
            column={1}
          >
            {invoice &&
              Object.keys(invoice.summary)
                .filter((k) => k !== 'total')
                .map((k: string) => (
                  <Descriptions.Item
                    key={k}
                    label={getInvoiceConceptLabel(k as keyof IInvoiceSummary)}
                    labelStyle={{
                      textAlign: 'right',
                      wordBreak: 'break-word',
                    }}
                    contentStyle={{
                      textAlign: 'right',
                      wordBreak: 'inherit',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {invoice && (
                      <NumberField
                        value={invoice.summary[k as keyof IInvoiceSummary]}
                        options={{
                          style: 'currency',
                          currency: 'USD',
                        }}
                        style={{
                          color:
                            parseFloat(
                              invoice.summary[k as keyof IInvoiceSummary]
                            ) < 0
                              ? 'red'
                              : 'inherit',
                        }}
                      />
                    )}
                  </Descriptions.Item>
                ))}
            {invoice && (
              <Descriptions.Item
                label="Total"
                labelStyle={{
                  textAlign: 'right',
                  fontWeight: 700,
                }}
                contentStyle={{textAlign: 'right'}}
              >
                <strong>
                  <NumberField
                    value={invoice.summary.total}
                    options={{
                      style: 'currency',
                      currency: 'USD',
                    }}
                    style={{wordBreak: 'inherit', whiteSpace: 'nowrap'}}
                  />
                </strong>
              </Descriptions.Item>
            )}
          </Descriptions>
        </Space>
      </Card>
      <Modal
        open={refundModalOpen}
        title={`Refund invoice: ${invoice?.id}`}
        confirmLoading={loadingRefund}
        onOk={() => refundInvoice()}
        onCancel={() => setRefundModalOpen(false)}
      >
        <Typography.Text style={{display: 'block'}}>
          Are you sure you want to refund this invoice?
        </Typography.Text>
      </Modal>
    </>
  );
};
