import {Descriptions, Grid, Space} from 'antd';
import React from 'react';
import {ICourierAddress, IShippingAddress} from '../../../interfaces';

const {useBreakpoint} = Grid;

type Props = {
  address?: ICourierAddress | IShippingAddress;
};

export function getAdmin1Label(country?: string): string {
  switch (country) {
    case 'PE':
      return 'Department';
    case 'US':
      return 'State';
    default:
      return '';
  }
}

export function getAdmin2Label(country?: string): string {
  switch (country) {
    case 'PE':
      return 'Province';
    case 'US':
      return 'City';
    default:
      return '';
  }
}

export function getAdmin3Label(country?: string): string {
  switch (country) {
    case 'PE':
      return 'District';
    default:
      return '';
  }
}

export const ShowAddress: React.FC<Props> = (props) => {
  const {address} = props;

  const {xl, xs} = useBreakpoint();

  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
        textAlign: xl ? 'unset' : 'center',
      }}
    >
      {address && (
        <Descriptions column={xs ? 1 : 2}>
          {/*<Descriptions.Item label="ID">{address.id}</Descriptions.Item>*/}
          {'firstName' in address && (
            <Descriptions.Item label="First name">
              {address.firstName}
            </Descriptions.Item>
          )}
          {'lastName' in address && (
            <Descriptions.Item label="Last name">
              {address.lastName}
            </Descriptions.Item>
          )}
          {'name' in address && (
            <Descriptions.Item label="Name">{address.name}</Descriptions.Item>
          )}
          {'idNumber' in address && (
            <Descriptions.Item label="ID Number">
              {address.idNumber}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Phone">
            {address.phoneCountry.code} ({address.phoneCountry.phonePrefix}){' '}
            {address.phone}
          </Descriptions.Item>
          <Descriptions.Item label="Country">
            {address.phoneCountry.code}
          </Descriptions.Item>
          <Descriptions.Item label="Address1">
            {address.address1}
          </Descriptions.Item>
          {address.address2 && (
            <Descriptions.Item label="Address2">
              {address.address2}
            </Descriptions.Item>
          )}
          <Descriptions.Item label={getAdmin1Label(address.country.code)}>
            {address.admin1.name}
          </Descriptions.Item>
          <Descriptions.Item label={getAdmin2Label(address.country.code)}>
            {address.admin2.name}
          </Descriptions.Item>
          {address.admin3 && (
            <Descriptions.Item label={getAdmin3Label(address.country.code)}>
              {address.admin3.name}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="ZipCode">
            {address.zipCode}
          </Descriptions.Item>
        </Descriptions>
      )}
      {/*address && (
        <>
          <Typography.Text>
            <strong>ID:</strong> {address.id}
          </Typography.Text>
          {'firstName' in address && (
            <Typography.Text>
              <strong>First name:</strong> {address.firstName}
            </Typography.Text>
          )}
          {'lastName' in address && (
            <Typography.Text>
              <strong>Last name:</strong> {address.lastName}
            </Typography.Text>
          )}
          {'name' in address && (
            <Typography.Text>
              <strong>Name:</strong> {address.name}
            </Typography.Text>
          )}
          {'idNumber' in address && (
            <Typography.Text>
              <strong>ID Number:</strong> {address.idNumber}
            </Typography.Text>
          )}
          <Typography.Text>
            <strong>Phone:</strong> {address.phoneCountry.code} (
            {address.phoneCountry.phonePrefix}) {address.phone}
          </Typography.Text>
          <Typography.Text>
            <strong>Country:</strong> {address.phoneCountry.code}
          </Typography.Text>
          <Typography.Text>
            <strong>Address1:</strong> {address.address1}
          </Typography.Text>
          {address.address2 && (
            <Typography.Text>
              <strong>Address2:</strong> {address.address2}
            </Typography.Text>
          )}
          <Typography.Text>
            <strong>{getAdmin1Label(address.country.code)}:</strong>{' '}
            {address.admin1.name}
          </Typography.Text>
          <Typography.Text>
            <strong>{getAdmin2Label(address.country.code)}:</strong>{' '}
            {address.admin2.name}
          </Typography.Text>
          {address.admin3 && (
            <Typography.Text>
              <strong>{getAdmin3Label(address.country.code)}:</strong>{' '}
              {address.admin3.name}
            </Typography.Text>
          )}
          <Typography.Text>
            <strong>ZipCode:</strong> {address.zipCode}
          </Typography.Text>
        </>
      )*/}
    </Space>
  );
};
